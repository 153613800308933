import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

//Redux
import { connect } from 'react-redux';
import { editUserDetails } from '../../userActions';
import withStyles from '@material-ui/core/styles/withStyles';

import MyButton from '../../../../util/MyButton';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({});

class AgeEdit extends Component {
	constructor (props) {
		super(props);

		this.state = {
			age: '',
			open: false
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}
	handleOpen = () => {
		this.setState({
			open: true
		});
		this.mapUserDetailsToState(this.props.currentUser);
	};

	handleClose = () => {
		this.setState({
			open: false
		});
	};

	componentDidMount () {
		const { currentUser } = this.props;
		//TODO: Need to not run this if currentUser is undefined
		if (currentUser) {
			this.mapUserDetailsToState(currentUser);
		}
	}

	mapUserDetailsToState = (currentUser) => {
		this.setState({
			age: currentUser.age ? currentUser.age : ''
		});
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleSubmit = () => {
		let userDetails = {
			...this.props.currentUser
		};
		userDetails = {
			age: this.state.age
		};
		this.props.editUserDetails(this.props.currentUser.uid, userDetails);
		this.handleClose();
	};

	render () {
		const { classes } = this.props;
		return (
			<Fragment>
				<MyButton tip='Enter your Age' onClick={this.handleOpen} btnClassName={classes.button}>
					<p>+ Age</p>
				</MyButton>
				<Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth='sm'>
					<DialogTitle>Edit your Age</DialogTitle>
					<DialogContent>
						<form>
							{/* age */}
							<TextField
								name='age'
								type='text'
								label='Age'
								placeholder='Age'
								className={classes.textField}
								value={this.state.age}
								onChange={this.handleChange}
								fullWidth
							/>
						</form>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color='primary'>
							Cancel
						</Button>
						<Button onClick={this.handleSubmit} color='primary'>
							Save
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	currentUser: state.user.credentials,
	curr: state.user.currentUser
});

AgeEdit.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	uploadImage: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired
};

AgeEdit.propTypes = {
	editUserDetails: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { editUserDetails })(withStyles(styles)(AgeEdit));
