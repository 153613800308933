import React, { Component } from 'react';
import PropTypes from 'prop-types';

//Redux
import { connect } from 'react-redux';
import { editUserDetails } from '../userActions';

import './Setup.css';

import { Row } from 'antd';

import { Link } from 'react-router-dom';

class AgeSetup extends Component {
	constructor (props) {
		super(props);

		this.state = {
			ageGroup: ''
		};
		//this.handleClick = this.handleClick.bind(this);

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleClick = (e, group) => {
		e.target.focus();
		this.setState({
			ageGroup: group
		});
	};

	handleSubmit = () => {
		let userDetails = {
			...this.props.currentUser
		};
		userDetails = {
			ageGroup: this.state.ageGroup
		};
		this.props.parentCallback();
		this.props.editUserDetails(this.props.currentUser.uid, userDetails);
	};

	render () {
		return (
			<div className='ProfileSetup'>
				<Row type='flex' justify='center'>
					<p className='edithead'> Tell Us About Your Age Group</p>
				</Row>

				<Row type='flex' justify='center'>
					<button className='age' onClick={(e) => this.handleClick(e, '<40')}>
						Less than 40 years
					</button>
					<button className='age' onClick={(e) => this.handleClick(e, '61-70')}>
						61 - 70 years
					</button>
				</Row>
				<Row type='flex' justify='center'>
					<button className='age' onClick={(e) => this.handleClick(e, '40-50')}>
						40 - 50 years
					</button>
					<button className='age' onClick={(e) => this.handleClick(e, '<70')}>
						70 + years
					</button>
				</Row>
				<Row type='flex' justify='center'>
					<button className='age' onClick={(e) => this.handleClick(e, '51-60')}>
						51 - 60 years
					</button>
					<button className='age' onClick={(e) => this.handleClick(e, 'youngenough')}>
						Young enough!
					</button>
				</Row>

				<br />
				<Row type='flex' justify='center'>
					<Link to='./'>
						<button className='continue' href='./' onClick={this.handleSubmit}>
							Continue
						</button>
					</Link>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	currentUser: state.user.credentials,
	curr: state.user.currentUser
});

AgeSetup.propTypes = {
	editUserDetails: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { editUserDetails })(AgeSetup);
