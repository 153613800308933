const config = {
	production: {
		apiKey: process.env.REACT_APP_API_KEY,
		authDomain: process.env.REACT_APP_AUTH_DOMAIN,
		databaseURL: process.env.REACT_APP_DATABASE_URL,
		projectId: process.env.REACT_APP_PROJECT_ID,
		storageBucket: process.env.REACT_APP_STOPAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_APP_ID,
		measurementId: process.env.REACT_APP_MEASUREMENT_ID
	},
	development: {
		apiKey: process.env.REACT_APP_API_KEY_DEV,
		authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
		projectId: process.env.REACT_APP_PROJECT_ID_DEV,
		storageBucket: process.env.REACT_APP_STOPAGE_BUCKET_DEV,
		messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
		appId: process.env.REACT_APP_APP_ID_DEV,
		measurementId: process.env.REACT_APP_MEASUREMENT_ID_DEV
	}
};

module.exports = config;
