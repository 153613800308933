import {
	SET_USER,
	SET_ERRORS,
	CLEAR_ERRORS,
	LOADING_UI,
	SET_UNAUTHENTICATED,
	LOADING_USER,
	LOADING_USER_DOC,
	SET_USER_DOC,
	EDITING_USER_DOC,
	SET_USER_INT,
	SET_INTERESTED_EVENTS,
	SET_INTERESTED_EVENTS_FAIL,
	RESET_START,
	RESET_SUCCESS,
	RESET_FAIL
} from '../../redux/types';

import firebase from '../../components/Firebase/firebase';

export const loginUser = (userData, history) => (dispatch) => {
	dispatch({ type: LOADING_UI });

	try {
		firebase
			.auth()
			.signInWithEmailAndPassword(userData.email, userData.password)
			.then((data) => {
				dispatch({ type: LOADING_USER });
				dispatch({
					type: SET_USER,
					payload: data
				});
				// we don't set the currentUser here
				// but we still get the currentUser doc but that is
				// most likely because of verifyAuth action we created
				// that is setting the doc
				dispatch({ type: CLEAR_ERRORS });
				// push is the method used in react for redirecting
				//so her '/' is the path to our home page
				history.push('/');
			})
			.catch((err) => {
				console.log(err);
				if (err.code === 'auth/wrong-password') {
					dispatch({
						type: SET_ERRORS,
						payload: {
							general: 'The password is incorrect. Please try again.'
						}
					});
				} else if (err.code === 'auth/user-not-found') {
					dispatch({
						type: SET_ERRORS,
						payload: {
							general: 'There is no user linked to this email. The user may have been deleted.'
						}
					});
				} else {
					dispatch({
						type: SET_ERRORS,
						payload: {
							general: err.message
						}
					});
				}
			});
	} catch (error) {
		dispatch({
			type: SET_ERRORS,
			payload: error
		});
	}
};

export const signupUser = (newUserData, history) => async (dispatch, getState) => {
	dispatch({ type: LOADING_UI });
	try {
		let createdUser = await firebase.auth().createUserWithEmailAndPassword(newUserData.email, newUserData.password);

		await createdUser.user.updateProfile({
			displayName: newUserData.handle
		});
		let newUser = {
			displayName: newUserData.handle,
			createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
			userID: createdUser.user.uid,
			email: newUserData.email
		};

		await firebase.firestore().collection('users').doc(`${createdUser.user.uid}`).set({ ...newUser });
		history.push('/?postsignup=true');
	} catch (error) {
		console.log(error);
		if (error.code === 'auth/email-already-in-use') {
			dispatch({ type: SET_ERRORS, payload: { email: error.message } });
		} else if (error.code === 'auth/weak-password') {
			dispatch({ type: SET_ERRORS, payload: { password: error.message } });
		} else {
			dispatch({ type: SET_ERRORS, payload: error });
		}
	}
};

export const logoutUser = () => (dispatch) => {
	firebase.auth().signOut();
	dispatch({ type: SET_UNAUTHENTICATED });
};

export const uploadImage = (formData) => (dispatch) => {
	dispatch({ type: LOADING_USER });
};

export const verifyAuth = () => (dispatch) => {
	dispatch({ type: LOADING_USER });

	firebase.auth().onAuthStateChanged((user) => {
		if (user !== null) {
			dispatch({
				type: SET_USER,
				payload: user
			});
			dispatch(getUserDetails(user.uid));
		} else {
			dispatch({ type: SET_UNAUTHENTICATED });
		}
		dispatch({ type: CLEAR_ERRORS });
	});
};

export const getUserDetails = (uid) => (dispatch) => {
	dispatch({ type: LOADING_USER_DOC });
	firebase.firestore().collection('users').doc(`${uid}`).onSnapshot((snapshot) => {
		if (snapshot) {
			dispatch({
				type: SET_USER_DOC,
				payload: snapshot.data()
			});
			dispatch({ type: CLEAR_ERRORS });
		} else {
			dispatch({ type: SET_UNAUTHENTICATED });
		}
	});
	dispatch(getUserInterestedEvents(uid));
};

export const getUserInterestedEvents = (uid) => (dispatch) => {
	firebase.firestore().collection('UserInterested').doc(`${uid}`).onSnapshot((snapshot) => {
		if (snapshot) {
			dispatch({
				type: SET_USER_INT,
				payload: snapshot.data()
			});
			dispatch({ type: CLEAR_ERRORS });
		} else {
			dispatch({ type: SET_UNAUTHENTICATED });
		}
	});
};

export const getInterestedEvents = (eventIds) => (dispatch) => {
	const eventIdArray = [];
	for (const eventId of eventIds) {
		eventIdArray.push(firebase.firestore().collection('ShortEvents').doc(eventId).get());
	}

	Promise.all(eventIdArray)
		.then((docArray) => {
			const events = [];
			docArray.forEach((doc) => {
				events.push({ ...doc.data(), uid: doc.id });
			});
			dispatch({
				type: SET_INTERESTED_EVENTS,
				payload: events
			});
		})
		.catch((e) => {
			console.error(e);
			dispatch({
				type: SET_INTERESTED_EVENTS_FAIL,
				payload: []
			});
		});
};

export const editUserDetails = (uid, userDetails) => (dispatch) => {
	dispatch({ type: EDITING_USER_DOC });
	firebase
		.firestore()
		.collection('users')
		.doc(`${uid}`)
		.set(userDetails, { merge: true })
		.then(() => {
			dispatch(getUserDetails(uid));
		})
		.catch((error) => {
			console.log('Something went wrong when updating profile');
		});
};

export const resetUserPassword = (data) => async (dispatch, getState) => {
	dispatch({ type: RESET_START });
	try {
		await firebase.auth().sendPasswordResetEmail(data.email);
		dispatch({ type: RESET_SUCCESS });
	} catch (err) {
		if (err.code === 'auth/user-not-found') {
			dispatch({
				type: RESET_FAIL,
				payload: 'There is no account linked to this email. Please sign up for an account'
			});
		} else {
			dispatch({ type: RESET_FAIL, payload: err.message });
		}
		console.log(err);
	}
};
