import React from 'react';

//import { Collapse } from "antd";
import { Card } from 'antd';
import AgeEdit from './EditDetails/AgeEdit';
import EthnicityEdit from './EditDetails/EthnicityEdit';
import MaritalEdit from './EditDetails/MaritalEdit';

//const { Panel } = Collapse;

const AboutMe = ({ currentUser }) => {
	return (
		<div>
			<Card>
				<h1 className='AboutHead'>About Me</h1>

				{currentUser.age === '' || currentUser.age == null ? (
					<p className='info'>
						{' '}
						<AgeEdit />{' '}
					</p>
				) : (
					<p className='info'>Age: {currentUser.age}</p>
				)}
				{currentUser.maritalStatus === '' || currentUser.maritalStatus == null ? (
					<p className='info'>
						{' '}
						<MaritalEdit />{' '}
					</p>
				) : (
					<p className='info'>Marital Status: {currentUser.maritalStatus}</p>
				)}
				{currentUser.ethnicity === '' || currentUser.ethnicity == null ? (
					<p className='info'>
						{' '}
						<EthnicityEdit />{' '}
					</p>
				) : (
					<p className='info'>Ethnicity: {currentUser.ethnicity}</p>
				)}
			</Card>
		</div>
	);
};

export default AboutMe;
