import {
	SET_USER,
	SET_AUTHENTICATED,
	SET_UNAUTHENTICATED,
	LOADING_USER,
	SET_USER_DOC,
	SET_USER_INT,
	SET_INTERESTED_EVENTS,
	SET_INTERESTED_EVENTS_FAIL,
	RESET_START,
	RESET_SUCCESS,
	RESET_FAIL
} from '../../redux/types';

const initialState = {
	authenticated: false,
	credentials: {},
	currentUser: {},
	likes: [],
	loading: false,
	notifications: [],
	recoverPassword: {
		error: null,
		loading: false
	},
	userInterested: {
		interests: []
	},
	interestedEvents: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_AUTHENTICATED:
			return {
				...state,
				authenticated: true
			};
		case SET_UNAUTHENTICATED:
			return initialState;
		case SET_USER:
			return {
				...state,
				authenticated: true,
				loading: false,
				credentials: action.payload
			};
		case SET_USER_DOC:
			return {
				...state,
				currentUser: action.payload
			};
		case SET_USER_INT:
			return {
				...state,
				userInterested: action.payload
			};
		case SET_INTERESTED_EVENTS:
			return {
				...state,
				interestedEvents: action.payload,
				loading: false
			};
		case SET_INTERESTED_EVENTS_FAIL:
			return {
				...state,
				interestedEvents: [],
				loading: false
			};
		case LOADING_USER:
			return {
				...state,
				loading: true
			};
		case RESET_START:
			return {
				...state,
				recoverPassword: { ...state.recoverPassword, loading: true }
			};
		case RESET_SUCCESS:
			return {
				...state,
				recoverPassword: { ...state.recoverPassword, loading: false, error: false }
			};
		case RESET_FAIL:
			return {
				...state,
				recoverPassword: { ...state.recoverPassword, loading: false, error: action.payload }
			};
		default:
			return state;
	}
}
