import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import configObj from './config';
import 'firebase/analytics';

const env = process.env.REACT_APP_BUILD_ENV
	? process.env.REACT_APP_BUILD_ENV
	: process.env.NODE_ENV ? process.env.NODE_ENV : 'development';

const credentials = configObj[env];

firebase.initializeApp(credentials);
firebase.firestore();
firebase.auth();

const storage = firebase.storage();

export { storage, firebase as default };

//export default firebase;
