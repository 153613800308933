import React from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import { Link } from 'react-router-dom';
import AntTypography from 'antd/lib/typography';

export const Signup = ({ handleCancel, visible }) => {
	return (
		<Modal
			title='Please Login/Sign Up!'
			visible={visible}
			onCancel={handleCancel}
			footer={[
				<Button key='cancel' onClick={handleCancel}>
					Cancel
				</Button>
			]}
		>
			<AntTypography.Text>
				Please{' '}
				<Link to='/login' component={AntTypography.Link}>
					log in
				</Link>{' '}
				or create an account to use this feature:{' '}
				<Link to='/signup' component={AntTypography.Link}>
					Sign Up
				</Link>
			</AntTypography.Text>
		</Modal>
	);
};
