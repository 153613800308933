import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import img5 from '../../../images/learn.png';
import { connect } from 'react-redux';

import img1 from '../../../images/volunteer.png';
import img2 from '../../../images/tour.png';
import img3 from '../../../images/taste.png';
import img4 from '../../../images/community.png';
import onlineCard from '../../../images/online.png';
import videosCard from '../../../images/videos.png';
import wellnessCard from '../../../images/wellness.png';
import { updateFilterQuery } from '../../../components/FilterBar/filterActions';

const Link = require('react-router-dom').Link;

class CarouselItems extends Component {
	render () {
		//   *** New Code *** 2 CSS Classes and media query can select based on class
		const responsive = {
			superLargeDesktop: {
				// the naming can be any, depends on you.
				breakpoint: { max: 4000, min: 3000 },
				items: 5
			},
			desktop: {
				breakpoint: { max: 3000, min: 1024 },
				items: 5
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 3
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 2
			}
		};
		const { updateFilterQuery } = this.props;
		return (
			<div>
				<Carousel responsive={responsive}>
					<div>
						<Link key={`${onlineCard}`} to={'/searchResults'}>
							<img
								style={{ width: '75%', cursor: 'pointer' }}
								alt={'online'}
								src={onlineCard}
								onClick={() => updateFilterQuery({ online: true })}
							/>
						</Link>
					</div>

					<div>
						<Link key={`${videosCard}`} to={'/searchResults'}>
							<img
								style={{ width: '75%', cursor: 'pointer' }}
								alt={'videos'}
								src={videosCard}
								onClick={() => updateFilterQuery({ videoFilter: true })}
							/>
						</Link>
					</div>

					<div>
						<Link key={`${img5}`} to={'/searchResults/Learn'}>
							<img
								style={{ width: '75%', cursor: 'pointer' }}
								alt={'learn'}
								src={img5}
								onClick={() => updateFilterQuery({ eventType: 'Learn' })}
							/>
						</Link>
					</div>

					{/*
					<div>
						<Link key={`${wellnessCard}`} to={'/searchResults'}>
							<img
								style={{ width: '75%', cursor: 'pointer' }}
								alt={'wellness'}
								src={wellnessCard}
								onClick={() => updateFilterQuery({ eventType: 'Wellness' })}
							/>
						</Link>
					</div>
*/}
					<div>
						<Link key={`${img4}`} to={'/searchResults/Community'}>
							<img
								style={{ width: '75%', cursor: 'pointer' }}
								alt={'community'}
								src={img4}
								onClick={() => updateFilterQuery({ eventType: 'Community' })}
							/>
						</Link>
					</div>

					<div>
						<Link key={`${img1}`} to={'/searchResults/Volunteer'}>
							<img
								style={{ width: '75%', cursor: 'pointer' }}
								alt={'volunteer'}
								src={img1}
								onClick={() => updateFilterQuery({ eventType: 'Volunteer' })}
							/>
						</Link>
					</div>
					<div>
						<Link key={`${img2}`} to={'/searchResults/Tour'}>
							<img
								style={{ width: '75%', cursor: 'pointer' }}
								alt={'tour'}
								src={img2}
								onClick={() => updateFilterQuery({ eventType: 'Tour' })}
							/>
						</Link>
					</div>

					<div>
						<Link key={`${img3}`} to={'/searchResults/Taste'}>
							<img
								style={{ width: '75%', cursor: 'pointer' }}
								alt={'taste'}
								src={img3}
								onClick={() => updateFilterQuery({ eventType: 'Taste' })}
							/>
						</Link>
					</div>
				</Carousel>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	data: state.events,
	events: state.events.events
});

const mapDispatchToProps = {
	updateFilterQuery
};
export default connect(mapStateToProps, mapDispatchToProps)(CarouselItems);
