import React from 'react';


import BioEdit from './EditDetails/BioEdit'
import EditProfile from './EditProfile';


const ProfileHeader = ({ currentUser ,userID}) => {
    return(
        <div>
            <h1 className = "ProfileHead">Hi, I'm {currentUser.name}</h1>
            <h2 className = "ProfileSec">Joined in 2020 · <EditProfile currentUser={currentUser} userID={userID} />{/*<a href = "./profile/EditProfile" style= {{color: "#3297a8"}}>Edit Profile</a>*/}</h2> 
            {currentUser.bio === "" ||
            currentUser.bio == null ? (
                <p> <BioEdit/> </p>
                ) : (
                <p className = 'bio'>{currentUser.bio}</p>
            )}
        </div>
    )

}

export default ProfileHeader;