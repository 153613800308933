import React, { Fragment, useState } from 'react';
//import PropTypes from "prop-types";
//import withStyles from "@material-ui/core/styles/withStyles";

//MUI
import Button from '@material-ui/core/Button';
//Antd components
import Modal from 'antd/lib/modal';
//import TextField from "@material-ui/core/TextField";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Video from './Video';

import firebase from '../../../../components/Firebase/firebase';
import { triggerFirebaseAnalytics } from '../../../../components/Firebase/utils';

//css
import './RedirectWarning.css';

const RedirectWarning = ({ ...props }) => {
	const [ isOpen, setIsOpen ] = useState(false);
	const [ donationOpen, setDonationOpen ] = useState(false);
	const [ skipDonation, setSkipDonation ] = useState(false);

	const handleOpen = () => {
		const { event, show } = props;
		if (show) {
			triggerFirebaseAnalytics('watch_now_open', {
				event_name: event.eventName,
				is_free: event.pricePerPerson ? (event.pricePerPerson === 0 ? true : false) : true,
				event_type: event.eventTypeFilter,
				is_online: event.online ? event.online : false
			});
		} else {
			triggerFirebaseAnalytics('book_now_open', {
				event_name: event.eventName,
				is_free: event.pricePerPerson ? (event.pricePerPerson === 0 ? true : false) : true,
				event_type: event.eventTypeFilter,
				is_online: event.online ? event.online : false
			});
		}
		const { donationUrl } = event;
		if (donationUrl) {
			setDonationOpen(true);
		} else {
			setIsOpen(true);
		}
	};
	const handleClose = () => {
		setIsOpen(false);
		setDonationOpen(false);
	};
	const handleSubmit = () => {
		firebase.analytics().logEvent('BookNow_Completed');
		const { url } = props;
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) {
			newWindow.opener = null;
		}
		handleClose();
	};
	const handleSkip = () => {
		setDonationOpen(false);
		setSkipDonation(true);
		setIsOpen(true);
	};

	const { event, show } = props;
	const { donationUrl, donationMessage, recordingUrl } = event;
	const displayMessage = donationMessage ? donationMessage : 'If you can and would like to, please donate.';
	return (
		<Fragment>
			<div className='bookNowButtonDiv'>
				<Button
					className='bookNowButton'
					onClick={handleOpen}
					variant='contained'
					color='primary'
					size='small'
					style={{ fontSize: '18px' }}
				>
					{show ? 'Watch Now' : 'Book Now'}
				</Button>
			</div>
			{show ? (
				<Modal
					visible={isOpen || donationOpen}
					title={'Video'}
					onOk={handleClose}
					onCancel={handleClose}
					footer={[]}
				>
					<Video videolink={recordingUrl} />
				</Modal>
			) : (
				<Fragment>
					{donationUrl && (
						<Modal
							visible={donationOpen}
							title={'Donations'}
							onOk={handleClose}
							onCancel={handleClose}
							footer={[
								<Button key='back' onClick={handleClose}>
									Cancel
								</Button>,
								<Button key='submit' color='primary' onClick={handleSkip}>
									Skip
								</Button>
							]}
						>
							<p>
								{displayMessage}{' '}
								<a href={donationUrl} target='_blank' rel='noopener noreferrer'>
									Click here!
								</a>
							</p>
						</Modal>
					)}
					{(!donationUrl || skipDonation) && (
						<Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='sm'>
							<DialogTitle>
								You are being redirected to another page, would you like to continue?
							</DialogTitle>

							<DialogActions>
								<Button onClick={handleClose} color='primary'>
									Stay On Page
								</Button>
								<Button onClick={handleSubmit} color='primary'>
									Continue
								</Button>
							</DialogActions>
						</Dialog>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default RedirectWarning;
