import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import MyButton from '../../../../util/MyButton';

//Redux
import { connect } from 'react-redux';
import { editUserDetails } from '../../userActions';

//MUI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';

//Icons
//import EditIcon from "@material-ui/icons/Edit";
import { FormGroup } from '@material-ui/core';

const styles = (theme) => ({
	...theme.spreadIt,
	button: {
		color: '#367786'
	}
});

class InterestEdit extends Component {
	constructor (props) {
		super(props);

		this.state = {
			likeTaste: false,
			likeTour: false,
			likeLearn: false,
			likeVolunteer: false,
			likeCommunity: false,
			open: false,

			volunteerDetails: '',
			learnDetails: '',
			tourDetails: '',
			tasteDetails: '',
			communityDetails: ''
		};
	}

	handleOpen = () => {
		this.setState({
			open: true
		});
		this.mapUserDetailsToState(this.props.currentUser);
	};

	handleClose = () => {
		this.setState({
			open: false
		});
	};

	componentDidMount () {
		const { currentUser } = this.props;
		//TODO: Need to not run this if currentUser is undefined
		if (currentUser) {
			this.mapUserDetailsToState(currentUser);
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleChangeCheckbox = (event) => {
		this.setState({ [event.target.name]: event.target.checked });
	};

	handleSubmit = () => {
		let userDetails = {
			...this.props.currentUser
		};
		userDetails = {
			likeTaste: this.state.likeTaste,
			likeTour: this.state.likeTour,
			likeLearn: this.state.likeLearn,
			likeVolunteer: this.state.likeVolunteer,
			likeCommunity: this.state.likeCommunity,

			volunteerDetails: this.state.volunteerDetails,
			learnDetails: this.state.learnDetails,
			tourDetails: this.state.tourDetails,
			tasteDetails: this.state.tasteDetails,
			communityDetails: this.state.communityDetails
		};
		this.props.editUserDetails(this.props.userID, userDetails);
		this.handleClose();
	};

	mapUserDetailsToState = (currentUser) => {
		this.setState({
			likeTaste: currentUser.likeTaste ? currentUser.likeTaste : false,
			likeTour: currentUser.likeTour ? currentUser.likeTour : false,
			likeLearn: currentUser.likeLearn ? currentUser.likeLearn : false,
			likeVolunteer: currentUser.likeVolunteer ? currentUser.likeVolunteer : false,
			likeCommunity: currentUser.likeCommunity ? currentUser.likeCommunity : false,

			volunteerDetails: currentUser.volunteerDetails ? currentUser.volunteerDetails : '',
			learnDetails: currentUser.learnDetails ? currentUser.learnDetails : '',
			tourDetails: currentUser.tourDetails ? currentUser.tourDetails : '',
			tasteDetails: currentUser.tasteDetails ? currentUser.tasteDetails : '',
			communityDetails: currentUser.communityDetails ? currentUser.communityDetails : ''
		});
	};

	render () {
		const { classes } = this.props;
		return (
			<Fragment>
				<MyButton
					tip='Edit Details'
					onClick={this.handleOpen}
					btnClassName={classes.button}
					style={{ color: '#367786' }}
				>
					<p>Edit</p>
					{/*<EditIcon color="primary" />  */}
				</MyButton>
				<Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth='sm'>
					<DialogTitle>Edit your details</DialogTitle>
					<DialogContent>
						<form>
							<header>Travonde Event Preferences</header>
							<FormGroup>
								<FormControlLabel
									name='likeTaste'
									control={
										<Checkbox
											color='primary'
											checked={this.state.likeTaste}
											value={this.state.likeTaste}
											onChange={this.handleChangeCheckbox}
										/>
									}
									label={'Taste'}
									labelPlacement='end'
								/>
								<TextField
									name='tasteDetails'
									type='text'
									label='Taste Details'
									placeholder='Add Any More Details about Taste'
									className={classes.textField}
									value={this.state.tasteDetails}
									onChange={this.handleChange}
									fullWidth
								/>

								<FormControlLabel
									name='likeTour'
									control={
										<Checkbox
											color='primary'
											checked={this.state.likeTour}
											value={this.state.likeTour}
											onChange={this.handleChangeCheckbox}
										/>
									}
									label={'Tour'}
									labelPlacement='end'
								/>
								<TextField
									name='tourDetails'
									type='text'
									label='Tour Details'
									placeholder='Add Any More Details about Tour'
									className={classes.textField}
									value={this.state.tourDetails}
									onChange={this.handleChange}
									fullWidth
								/>

								<FormControlLabel
									name='likeLearn'
									control={
										<Checkbox
											color='primary'
											checked={this.state.likeLearn}
											value={this.state.likeLearn}
											onChange={this.handleChangeCheckbox}
										/>
									}
									label={'Learn'}
									labelPlacement='end'
								/>
								<TextField
									name='learnDetails'
									type='text'
									label='Learn Details'
									placeholder='Add Any More Details about Learn'
									className={classes.textField}
									value={this.state.learnDetails}
									onChange={this.handleChange}
									fullWidth
								/>

								<FormControlLabel
									name='likeVolunteer'
									control={
										<Checkbox
											color='primary'
											checked={this.state.likeVolunteer}
											value={this.state.likeVolunteer}
											onChange={this.handleChangeCheckbox}
										/>
									}
									label={'Volunteer'}
									labelPlacement='end'
								/>
								<TextField
									name='volunteerDetails'
									type='text'
									label='Volunteer Details'
									placeholder='Add Any More Details about Volunteer'
									className={classes.textField}
									value={this.state.volunteerDetails}
									onChange={this.handleChange}
									fullWidth
								/>

								<FormControlLabel
									name='likeCommunity'
									control={
										<Checkbox
											color='primary'
											checked={this.state.likeCommunity}
											value={this.state.likeCommunity}
											onChange={this.handleChangeCheckbox}
										/>
									}
									label={'Community'}
									labelPlacement='end'
								/>
								<TextField
									name='communityDetails'
									type='text'
									label='Community Details'
									placeholder='Add Any More Details about Community'
									className={classes.textField}
									value={this.state.communityDetails}
									onChange={this.handleChange}
									fullWidth
								/>
							</FormGroup>
						</form>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color='primary'>
							Cancel
						</Button>
						<Button onClick={this.handleSubmit} color='primary'>
							Save
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		);
	}
}

InterestEdit.propTypes = {
	editUserDetails: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired
};
//TODO: Could use mapStateToProps if we needed more information
// but as of right now we are getting currentUser information and uid from ProfilePage
// component as props
export default connect(null, { editUserDetails })(withStyles(styles)(InterestEdit));
