import React, { Fragment, Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import EventCard from '../../../../components/Events/EventCard';
import Flex from '../../../../components/Flex';
import { ShareModal } from '../../../../components/ShareModal/ShareModal';
import { Signup } from '../../../../components/Signup/Signup';
import MetaTags from 'react-meta-tags';

//MUI Stuff
import { Grid, Row, Col } from 'react-flexbox-grid';

//import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
//Redux
import { connect } from 'react-redux';
import { getEvent, getRelatedEvents } from '../../dataActions';
import { setEventID } from '../eventPageActions';
import { getUserDetails, getUserInterestedEvents } from '../../../UserProfile/userActions';
import EventAmenities from './EventAmenities';
import EventLocationDetails from './EventLocationDetails';
import './fullEventDetails.css';
//import { format } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import RedirectWarning from './RedirectWarning';
//import Moment from "react-moment";
import share from './share-1.png';
import heart from './heart.png';
import star from './star.png';
import filledheart from './heart (3).png';

import firebase from '../../../../components/Firebase/firebase';
import {triggerFirebaseAnalytics} from '../../../../components/Firebase/utils';
import Video from './Video';
//import { DateTime, Duration, Interval } from "luxon";

const styles = (theme) => ({
	...theme.spreadIt,
	invisibleSeparator: {
		border: 'none',
		margin: 4
	},
	eventImage: {
		maxWidth: '100%',
		height: 400
	},
	dialogContent: {
		padding: 20
	},
	title: {
		textAlign: 'center'
	},
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper
	},
	gridList: {
		flexWrap: 'nowrap',
		// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
		transform: 'translateZ(0)'
	},
	gridTitle: {
		color: theme.palette.primary.light
	},
	titleBar: {
		background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
	}
});

const setDescription = (event) => {
	if (event.eventTypeFilter === 'Volunteer') {
		return 'Volunteering';
	} else {
		if (event.pricePerPerson === 0) {
			return 'Free';
		} else {
			if (event.pricePerPerson) {
				return '$' + event.pricePerPerson + ' per person';
			} else if (event.price) {
				return '$' + event.price + ' per person';
			} else {
				return '';
			}
		}
	}
};

const urlify = (text) => {
	const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/g;

	if (urlRegex.test(text)) {
		return text.replace(urlRegex, (url) => {
			return '<a href="' + url + '" target="_blank" rel="noreferrer noopener">' + url + '</a>';
		});
	} else {
		return text;
	}
};

class FullEvent extends Component {
	constructor (props) {
		super(props);
		this.firstRender = false;
		this.state = {
			checked: false,
			interested: false,
			visible: false,
			paymentOpen: false,
			signup: false
		};
	}

	componentDidMount () {
		//TODO: need to display message of 404 event not found
		this.props.getEvent(this.props.match.params.id);
		this.props.setEventID(this.props.match.params.id);
		this.props.getRelatedEvents(this.props.match.params.id);
		const { uid } = this.props;
		if (uid) {
			this.props.getUserInterestedEvents(uid);
		}

	}
	componentDidUpdate (prevProps, prevState) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.props.getEvent(this.props.match.params.id);
			this.props.getRelatedEvents(this.props.match.params.id);
		}
	}

	onChange = (date) => this.setState({ date });

	onClick = (event, props) => {
		this.setState({ checked: !this.state.checked });
	};

	showModal = () => {
		this.setState({
			visible: true
		});
		const{event} = this.props;
		const{eventName,pricePerPerson,eventTypeFilter,online} = event
		triggerFirebaseAnalytics("share_event_click", {event_name: eventName, 
			is_free: pricePerPerson? pricePerPerson=== 0? true: false: true, 
			event_type: eventTypeFilter, 
			is_online: online? online: false});
	};

	handleOk = (e) => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	handleCancel = (e) => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	handleCancelSignup = (e) => {
		console.log(e);
		this.setState({
			signup: false
		});
	};

	clickInterested = () => {
		if (this.props.authenticated) {
			const eventdoc = firebase.firestore().collection('UserInterested').doc(`${this.props.uid}`);
			const eventid = this.props.match.params.id;

			eventdoc.get().then((docSnapshot) => {
				if (docSnapshot.exists) {
					var ints = docSnapshot.data().interests;
					if (!ints.includes(eventid)) {
						eventdoc.update({ interests: firebase.firestore.FieldValue.arrayUnion(eventid) });
						
						this.setState({ interested: true });
						const{event} = this.props;
						const{eventName,pricePerPerson,eventTypeFilter,online} = event
						triggerFirebaseAnalytics("interested_event_click", {event_name: eventName, 
							is_free: pricePerPerson? pricePerPerson=== 0? true: false: true, 
							event_type: eventTypeFilter, 
							is_online: online? online: false});

					} else {
						eventdoc.update({ interests: firebase.firestore.FieldValue.arrayRemove(eventid) });
						this.setState({ interested: false });
					}
				} else {
					eventdoc.set({ interests: [ eventid ] });
				}
			});
		} else {
			console.log('plz log on');
			this.setState({ interested: false, signup: true });
		}
	};

	render () {
		const { visible, signup } = this.state;

		const { UI: { loading }, event, relatedEvents, userInterested } = this.props;

		if (!this.firstRender  && event && Object.keys(event).length !==0) 
			{triggerFirebaseAnalytics("visit_event_detail", {event_name: event.eventName, 
																	is_free: event.pricePerPerson? event.pricePerPerson=== 0? true: false: true, 
																	event_type: event.eventTypeFilter, 
																	is_online: event.online? event.online: false});
				this.firstRender = true;
			}
		let metaTagUrl = null;
		let metaTagTitle = null;
		if (event) {
			const { imageUrl, eventName } = event;
			metaTagTitle = eventName;
			metaTagUrl = imageUrl;
		}

		const convertedEventDate =
			typeof event.eventDate === 'object'
				? new Date(event.eventDate.toDate())
				: typeof event.eventDate === 'string' ? new Date(event.eventDate) : null;
		const newDate = new Date();

		// check when to show video
		const passed = convertedEventDate < newDate;
		let show = false;
		//(event.recordingUrl !== null || event.recordingUrl !== "")
		if (passed && event.recordingUrl) {
			show = true;
		}
		let showOverview = false;
		if (!passed && event.recordingUrl) {
			showOverview = true;
		}

		const eventid = this.props.match.params.id;
		let interested = false;
		if (userInterested) {
			interested = userInterested.interests.includes(eventid);
		}

		let formattedSchedule = [];
		if (event && event.dailySchedule && event.dailySchedule !== '') {
			const dbDailySchedule = event.dailySchedule;
			const tempSchedule = dbDailySchedule.split(',').filter((substring) => substring !== '');
			for (let i = 0; i < tempSchedule.length; i++) {
				if (tempSchedule[i].trim().length > 1) {
					formattedSchedule.push(tempSchedule[i]);
				}
			}
		}

		let formattedLongDescription = null;
		if (event && event.longDescription) {
			let unformattedLongDescription = event.longDescription;
			while (unformattedLongDescription.includes('*')) {
				unformattedLongDescription = unformattedLongDescription.replace('*', '<br />');
			}
			formattedLongDescription = urlify(unformattedLongDescription);
		}

		let formattedImportantInformation = null;
		if (event && event.importantInformation) {
			let unformattedImportantInformation = event.importantInformation;
			while (unformattedImportantInformation.includes('*')) {
				unformattedImportantInformation = unformattedImportantInformation.replace('*', '<br />');
			}
			formattedImportantInformation = urlify(unformattedImportantInformation);
		}

		let EventDetails = !loading ? (
			<div className='container' style={{ backgroundColor: 'white' }}>
				<MetaTags>
					<meta property='og:title' content={metaTagTitle} />
					<meta property='og:image' content={metaTagUrl} />
				</MetaTags>
				<Grid fluid>
					<Row className='frontHalf'>
						<Col md={5}>
							<img src={event.imageUrl} alt='imageUrl' style={{ width: '100%', alignSelf: 'center' }} />
						</Col>
						<Col xs sm={7}>
							<Row>
								<div className='eventName' style={{ width: '100%' }}>
									{event.eventName}
									{event &&
									event.organizationName &&
									event.organizationName !== '' && (
										<p
											style={{ fontSize: '20px', marginLeft: '10px', marginTop: '20px' }}
										>{`by: ${event.organizationName}`}</p>
									)}
									{event && <EventLocationDetails event={event} />}
								</div>
								<div className='dateAndTime'>
									<br />
									{event &&
									event.rating && (
										<Fragment>
											<img
												src={star}
												alt='interested'
												style={{ width: '20px', height: '20px' }}
											/>{' '}
											{event.rating} Rating
										</Fragment>
									)}
									{/* <br />
                  <br /> */}
									{}
									{/* <p>Date and Time</p> */}
									{event &&
										event.dateAndTimeText &&
										event.dateAndTimeText
											.split('*')
											.filter((substring) => {
												if (substring.trim().length > 1) {
													return true;
												} else {
													return false;
												}
											})
											.map((time, index) => <span key={`${index}${time}`}>{time}</span>)}
									{/* {event && event.startDate != null && typeof event.startDate === 'object' ? (
										<span>{`${format(
											utcToZonedTime(new Date(event.startDate.toDate()), 'America/Los_Angeles'),
											"E LLL do, yyyy h:mm a' PST to '"
										)}`}</span>
									) : typeof event.startDate === 'string' && event.startDate !== '' ? (
										<Fragment>
											<span>
												{`${format(
													utcToZonedTime(new Date(event.startDate), 'America/Los_Angeles'),
													"E LLL do, yyyy h:mm a' PST to '"
												)}`}
											</span>
										</Fragment>
									) : null} */}

									<br />
									{/* {event && event.endDate != null && typeof event.endDate === 'object' ? (
										format(
											utcToZonedTime(new Date(event.endDate.toDate()), 'America/Los_Angeles'),
											"E LLL do, yyyy h:mm a ' PST '"
										)
									) : typeof event.endDate === 'string' && event.endDate !== '' ? (
										format(
											utcToZonedTime(new Date(event.endDate), 'America/Los_Angeles'),
											"E LLL do, yyyy h:mm a ' PST '"
										)
									) : null} */}
								</div>
								<br />
								<br />
								{event &&
								(event.pricePerPerson || event.pricePerPerson === 0) && (
									<div className='price'>{setDescription(event)}</div>
								)}
								<br />
								<br />
								{event &&
								Object.keys(event).length !== 0 && (
									<div className='bookNowButton'>
										<RedirectWarning url={event.bookingURL} event={event} show={show} />
									</div>
								)}
								<Row className='shareInterestedRow'>
									<button className='shareButton' onClick={this.showModal}>
										<img src={share} alt='share' className='share-1' /> {'   '}SHARE
									</button>
									<ShareModal
										visible={visible}
										handleCancel={this.handleCancel}
										url={eventid}
										event = {event}
									/>
									<button className='shareButton' onClick={this.clickInterested}>
										<img
											src={interested ? filledheart : heart}
											alt='interested'
											className='share-1'
										/>{' '}
										{'   '}INTERESTED
									</button>
									<Signup visible={signup} handleCancel={this.handleCancelSignup} />
								</Row>
							</Row>
						</Col>
					</Row>

					<h1>Overview</h1>
					{formattedLongDescription && (
						<div
							className='longDescription'
							key={`longDescription`}
							dangerouslySetInnerHTML={{ __html: formattedLongDescription }}
						/>
					)}
					{formattedImportantInformation && (
						<Fragment>
							<h1>Important Information</h1>
							<div
								className='longDescription'
								key={'importantInformation'}
								dangerouslySetInnerHTML={{ __html: formattedImportantInformation }}
							/>
						</Fragment>
					)}
					{formattedSchedule.length !== 0 && <h1>Hours</h1>}
					{formattedSchedule &&
						formattedSchedule.map((schedule, index) => (
							<div key={`${schedule}${index}`} className='hours'>
								{schedule}
							</div>
						))}
					<br />
					{showOverview ? (
						<div>
							{' '}
							<h1>Video</h1> <Video videolink={event.recordingUrl} />
						</div>
					) : null}

					{event && <EventAmenities event={event} />}
				</Grid>

				<hr />
				<br />
				{relatedEvents && relatedEvents.length > 0 && <div className='relatedEventsFont'>Related Events</div>}
				<div>
					<Flex flexDirection={'row'} flexWrap={'wrap'} flexFlow={'row wrap'} justifyContent={'flex-start'}>
						{!loading ? (
							relatedEvents &&
							relatedEvents.map((event) => (
								<Flex key={event.uid} justifyContent={'flex-start'} style={{ padding: 0, margin: 16 }}>
									<EventCard key={event.uid} event={event} />
								</Flex>
							))
						) : (
							<p>Loading...</p>
						)}
					</Flex>
				</div>
			</div>
		) : (
			<p>Loading...</p>
		);

		return event && <div>{EventDetails}</div>;
	}
}

FullEvent.propTypes = {
	getEvent: PropTypes.func.isRequired,
	event: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	event: state.events.event,
	UI: state.UI,
	relatedEvents: state.events.relatedEvents,
	fullEvents: state.events.fullEvents,
	authenticated: state.user.authenticated,
	uid: state.user.credentials.uid,
	user: state.user,
	userInterested: state.user.userInterested
});

const mapActionsToProps = {
	getEvent,
	getRelatedEvents,
	setEventID,
	getUserDetails,
	getUserInterestedEvents
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(FullEvent));
