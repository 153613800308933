import React from 'react';
//import { Typography, List } from "@material-ui/core";
import { Icon } from 'semantic-ui-react';
import './EventLocationDetails.css';

const EventLocationDetails = ({ event }) => {
	return (
		<div className='eventAddr' style={{ width: '100%' }}>
			{event && (!event.organizationName || event.organizationName === '') ? <br /> : null}
			<Icon name='map marker alternate' /> {event.streetAddress}
			<br />
		</div>
	);
};

export default EventLocationDetails;
