import React, { Component } from 'react';
import PropTypes from 'prop-types';

//Redux
import { connect } from 'react-redux';
import { editUserDetails } from '../userActions';

import './Setup.css';
//import Flex from "../../../components/Flex";

import { Row } from 'antd';

//import { Link } from 'react-router-dom';

import BusIcon from './BUS_TOUR_blue.png';
import CommunityIcon from './COMMUNITY_VOICE_blue.png';
import LearnIcon from './LEARN_BOOK_blue.png';
import TasteIcon from './ICE-CREAM-blue.png';
import VolunteerIcon from './VOLUNTEER_HEART_blue.png';

class interestsSetup extends Component {
	constructor (props) {
		super(props);

		this.state = {
			likeVolunteer: false,
			likeTaste: false,
			likeTour: false,
			likeLearn: false,
			likeCommunity: false
		};
		this.handleClickTaste = this.handleClickTaste.bind(this);
		this.handleClickTour = this.handleClickTour.bind(this);
		this.handleClickVolunteer = this.handleClickVolunteer.bind(this);
		this.handleClickLearn = this.handleClickLearn.bind(this);
		this.handleClickCommunity = this.handleClickCommunity.bind(this);

		this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount () {
		const { curr } = this.props;
		//TODO: Need to not run this if currentUser is undefined
		if (curr) {
			this.mapUserDetailsToState(curr);
		}
	}

	mapUserDetailsToState = (currentUser) => {
		this.setState({
			likeTaste: currentUser.likeTaste ? currentUser.likeTaste : false,
			likeTour: currentUser.likeTour ? currentUser.likeTour : false,
			likeLearn: currentUser.likeLearn ? currentUser.likeLearn : false,
			likeVolunteer: currentUser.likeVolunteer ? currentUser.likeVolunteer : false,
			likeCommunity: currentUser.likeCommunity ? currentUser.likeCommunity : false
		});
	};

	handleClickTaste = () => {
		this.setState((state) => ({
			likeTaste: !this.state.likeTaste
		}));
	};
	handleClickTour = () => {
		this.setState((state) => ({
			likeTour: !this.state.likeTour
		}));
	};
	handleClickLearn = () => {
		this.setState((state) => ({
			likeLearn: !this.state.likeLearn
		}));
	};
	handleClickVolunteer = () => {
		this.setState((state) => ({
			likeVolunteer: !this.state.likeVolunteer
		}));
	};
	handleClickCommunity = () => {
		this.setState((state) => ({
			likeCommunity: !this.state.likeCommunity
		}));
	};

	handleSubmit = () => {
		let userDetails = {
			...this.props.currentUser
		};
		userDetails = {
			likeTaste: this.state.likeTaste,
			likeTour: this.state.likeTour,
			likeLearn: this.state.likeLearn,
			likeVolunteer: this.state.likeVolunteer,
			likeCommunity: this.state.likeCommunity
		};
		this.props.parentCallback(userDetails);
		this.props.editUserDetails(this.props.currentUser.uid, userDetails);
	};

	render () {
		const somethingSelected =
			this.state.likeTour ||
			this.state.likeVolunteer ||
			this.state.likeLearn ||
			this.state.likeTaste ||
			this.state.likeCommunity;

		return (
			<div className='ProfileSetup'>
				<Row type='flex' justify='center'>
					<p className='edithead'>
						{' '}
						What Types of Events Are You Interested In? <br />
						Click All That Applies
					</p>
				</Row>

				<Row type='flex' justify='center'>
					<button
						className={this.state.likeVolunteer ? 'interests toggled' : 'interests'}
						onClick={this.handleClickVolunteer}
					>
						<img className='icons' src={VolunteerIcon} alt='' /> Volunteer
					</button>
					<button
						className={this.state.likeTaste ? 'interests toggled' : 'interests'}
						onClick={this.handleClickTaste}
					>
						<img className='icons' src={TasteIcon} alt='' /> Taste
					</button>
				</Row>
				<Row type='flex' justify='center'>
					<button
						className={this.state.likeTour ? 'interests toggled' : 'interests'}
						onClick={this.handleClickTour}
					>
						<img className='icons' src={BusIcon} alt='' />Tour
					</button>
					<button
						className={this.state.likeLearn ? 'interests toggled' : 'interests'}
						onClick={this.handleClickLearn}
					>
						<img className='icons' src={LearnIcon} alt='' />Learn
					</button>
				</Row>
				<Row type='flex' justify='center'>
					<button
						className={this.state.likeCommunity ? 'interests toggled' : 'interests'}
						onClick={this.handleClickCommunity}
					>
						<img className='icons' src={CommunityIcon} alt='' />Community
					</button>
				</Row>

				<br />
				<Row type='flex' justify='center'>
					{somethingSelected ? (
						<button className='continue' onClick={this.handleSubmit}>
							Continue
						</button>
					) : null}
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	currentUser: state.user.credentials,
	curr: state.user.currentUser
});

interestsSetup.propTypes = {
	editUserDetails: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { editUserDetails })(interestsSetup);
