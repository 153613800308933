import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';

//Redux
import { connect } from 'react-redux';
import { logoutUser, uploadImage } from '../userActions';

//MUI stuff
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

//import Flex from '../../../components/Flex';
import ProfilePicture from './ProfilePicture';
import AboutMe from './AboutMe';
import ProfileHeader from './ProfileHeader';
import Interests from './Interests';
import InterestedEvents from './InterestedEvents';

//import { Popover } from 'antd';
import { Grid, Row, Col } from 'react-flexbox-grid';

import './ProfilePage.css';

const styles = (theme) => ({
	paper: {
		padding: 20
	},
	profile: {
		'& .image-wrapper': {
			textAlign: 'center',
			position: 'relative',
			'& button': {
				position: 'absolute',
				top: '80%',
				left: '70%'
			}
		},
		'& .profile-image': {
			width: 200,
			height: 200,
			objectFit: 'cover',
			maxWidth: '100%',
			borderRadius: '50%'
		},
		'& .profile-details': {
			textAlign: 'center',
			'& span, svg': {
				verticalAlign: 'middle'
			},
			'& a': {
				color: '#00bcd4'
			},
			header: {
				fontSize: 14,
				fontWeight: 'bold'
			}
		},
		'& hr': {
			border: 'none',
			margin: '0 0 10px 0'
		},
		'& svg.button': {
			'&:hover': {
				cursor: 'pointer'
			}
		}
	},
	buttons: {
		textAlign: 'center',
		'& a': {
			margin: '20px 10px'
		}
	},
	button: {
		margin: theme.spacing(1)
	}
});

class ProfilePage extends Component {
	handleImageChange = (event) => {
		const image = event.target.files[0];
		const formData = new FormData();
		formData.append('image', image, image.name);
		this.props.uploadImage(formData);
	};

	handleEditPicture = () => {
		const fileInput = document.getElementById('imageInput');
		fileInput.click();
	};

	handleLogout = () => {
		this.props.logoutUser();
	};

	render () {
		const {
			classes,
			user: { loading, authenticated },
			//currentUser: { displayName, bio, interests },
			//currentUser,
			firestoreLoading,
			curr,
			userInterested
		} = this.props;

		let profileMarkup = !loading ? !firestoreLoading ? authenticated ? (
			<Paper className={classes.paper}>
				<div className={classes.profile}>
					{/* Profile Picture */}
					{/* {console.log(`current user is ${currentUser.displayName}`)}
              {console.log({ currentUser })} */}
					{curr ? (
						<div className='wrapper'>
							<Grid fluid>
								<Row>
									<Col xs lg={3}>
										<ProfilePicture
											currentUser={curr}
											userID={this.props.user.credentials.uid}
											handleImageChange={this.handleImageChange}
											handleEditPicture={this.handleEditPicture}
										/>
										<br />
										<AboutMe currentUser={curr} />
									</Col>
									<Col xs lg={6}>
										<Row>
											<div>
												<ProfileHeader
													currentUser={this.props.curr}
													userID={this.props.user.credentials.uid}
												/>
												<Interests
													currentUser={curr}
													userID={this.props.user.credentials.uid}
												/>
											</div>
										</Row>
									</Col>
								</Row>
								<div>
									{userInterested &&
									userInterested.interests && (
										<Row style={{ marginTop: '50px' }}>
											<Col xs lg={10}>
												<InterestedEvents />
											</Col>
										</Row>
									)}
								</div>
							</Grid>
							<hr />
							<br />
							<div />
						</div>
					) : null}
					{/*
			  		<div className = "wrapper">
					

					<Row>
			  		<div className = "leftside">
					
			  		

					<ProfilePicture 
						currentUser={curr}
						userID={this.props.user.credentials.uid}
						handleImageChange={this.handleImageChange}
						handleEditPicture={this.handleEditPicture}
					/>
					<br/>

			  		<AboutMe currentUser = {curr} /> 
					</div>

					<div className = "rightside">
					<ProfileHeader currentUser = {this.props.curr} userID={this.props.user.credentials.uid}/>

					<Interests currentUser = {curr} userID={this.props.user.credentials.uid}/>
					</div>
					</Row>
					<Row>
					<InterestedEvents></InterestedEvents>
					</Row>

				

					
</div> */}
					{/*

					{currentUser && (
						<ProfilePictureDetails
							currentUser={currentUser}
							handleImageChange={this.handleImageChange}
							handleEditPicture={this.handleEditPicture}
						/>
					)}
					*/}

					{/* Profile information */}

					{/*


					<div className="profile-details">
						<DisplayNameDetails displayName={displayName} Link={Link} />
						<hr />
						<PersonalInfoDetails bio={bio} interests={interests} />
						<hr />
						<header style={{ fontSize: 20, fontWeight: 'bold' }}>Travonde Event Preferences</header>
						<hr />

					*/}

					{/* <Event preferences> */}

					{/*
						<EventPreferencesDetails currentUser={currentUser} />
						<hr />
						<header style={{ fontSize: 20, fontWeight: 'bold' }}>Contact Information</header>
						<hr />

					*/}
					{/* Contact */}
					{/*<ContactInfoDetails currentUser={currentUser} /> 
					</div>
				*/}
					<br />
					{/* Logout */}
					<Button color='primary' onClick={this.handleLogout} className={classes.button} size={'large'}>
						Log Out
					</Button>
					{/*<EditProfile currentUser={this.props.curr} userID={this.props.user.credentials.uid} /> */}
				</div>
			</Paper>
		) : (
			<Paper className={classes.paper}>
				<Typography variant='body2' align='center'>
					No profile found, please login again
				</Typography>
				<div className={classes.buttons}>
					<Button variant='contained' color='primary' component={Link} to='/login'>
						Login
					</Button>
					<Button variant='contained' color='secondary' component={Link} to='/signup'>
						Sign Up
					</Button>
				</div>
			</Paper>
		) : (
			<p>Loading...</p>
		) : (
			<p>Loading...</p>
		);

		return profileMarkup;
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	currentUser: state.user.credentials,
	curr: state.user.currentUser,
	userInterested: state.user.userInterested
});

const mapActionsToProps = { logoutUser, uploadImage };

ProfilePage.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	uploadImage: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(ProfilePage));
