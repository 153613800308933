import React from 'react';
import PropTypes from 'prop-types';

const Flex = ({
	alignItems = 'flex-start',
	children,
	flexDirection = 'row',
	flexWrap = 'nowrap',
	justifyContent = 'flex-start',
	style = {}
}) => {
	const styles = {
		alignItems,
		flexDirection,
		display: 'flex',
		flexWrap,
		justifyContent,
		...style
	};
	return <div style={styles}>{children}</div>;
};

Flex.defaultProps = {
	alignItems: 'flex-start',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	justifyContent: 'flex-start',
	style: {}
};

Flex.propTypes = {
	alignItems: PropTypes.oneOf([ 'flex-start', 'flex-end', 'center', 'stretch', 'baseline' ]).isRequired,
	flexDirection: PropTypes.oneOf([ 'column', 'column-reverse', 'row', 'row-reverse' ]).isRequired,
	flexWrap: PropTypes.oneOf([ 'nowrap', 'wrap', 'wrap-reverse' ]),
	justifyContent: PropTypes.oneOf([
		'flex-start',
		'flex-end',
		'center',
		'space-between',
		'space-around',
		'space-evenly'
	]).isRequired,
	style: PropTypes.object
};

export default Flex;
