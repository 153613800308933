import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import MyButton from '../../../util/MyButton';

//Redux
import { connect } from 'react-redux';
import { editUserDetails } from '../userActions';

//MUI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Tooltip from '@material-ui/core/Tooltip';
// import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';

//Icons
//import EditIcon from "@material-ui/icons/Edit";
import { FormGroup } from '@material-ui/core';

const styles = (theme) => ({
	...theme.spreadIt,
	button: {
		color: '#367786'
	}
});

class EditProfile extends Component {
	constructor (props) {
		super(props);

		this.state = {
			bio: '',
			website: '',
			location: '',
			interests: '',
			phoneNum: '',
			likeTaste: false,
			likeTour: false,
			likeLearn: false,
			likeVolunteer: false,
			likeCommunity: false,
			open: false,
			name: '',
			age: '',
			maritalStatus: '',
			ethnicity: '',
			volunteerDetails: '',
			learnDetails: '',
			tourDetails: '',
			tasteDetails: '',
			communityDetails: '',
			likeMusicDance: false,
			likeArtsCraft: false,
			likeSocialize: false,
			likeHistory: false,
			likeGardening: false,
			likeSports: false,
			likeReading: false,
			likeMuseums: false,
			likeCooking: false,
			likePhotography: false
		};
	}

	handleOpen = () => {
		this.setState({
			open: true
		});
		this.mapUserDetailsToState(this.props.currentUser);
	};

	handleClose = () => {
		this.setState({
			open: false
		});
	};

	componentDidMount () {
		const { currentUser } = this.props;
		//TODO: Need to not run this if currentUser is undefined
		if (currentUser) {
			this.mapUserDetailsToState(currentUser);
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	handleChangeCheckbox = (event) => {
		this.setState({ [event.target.name]: event.target.checked });
	};

	handleSubmit = () => {
		let userDetails = {
			...this.props.currentUser
		};
		userDetails = {
			bio: this.state.bio,
			website: this.state.website,
			location: this.state.location,
			interests: this.state.interests,
			phoneNum: this.state.phoneNum,
			likeTaste: this.state.likeTaste,
			likeTour: this.state.likeTour,
			likeLearn: this.state.likeLearn,
			likeVolunteer: this.state.likeVolunteer,
			likeCommunity: this.state.likeCommunity,
			name: this.state.name,
			age: this.state.age,
			maritalStatus: this.state.maritalStatus,
			ethnicity: this.state.ethnicity,
			volunteerDetails: this.state.volunteerDetails,
			learnDetails: this.state.learnDetails,
			tourDetails: this.state.tourDetails,
			tasteDetails: this.state.tasteDetails,
			communityDetails: this.state.communityDetails,
			likeMusicDance: this.state.likeMusicDance,
			likeArtsCraft: this.state.likeArtsCraft,
			likeSocialize: this.state.likeSocialize,
			likeHistory: this.state.likeHistory,
			likeGardening: this.state.likeGardening,
			likeSports: this.state.likeSports,
			likeReading: this.state.likeReading,
			likeMuseums: this.state.likeMuseums,
			likeCooking: this.state.likeCooking,
			likePhotography: this.state.likePhotography
		};
		this.props.editUserDetails(this.props.userID, userDetails);
		this.handleClose();
	};

	mapUserDetailsToState = (currentUser) => {
		this.setState({
			bio: currentUser.bio ? currentUser.bio : '',
			website: currentUser.website ? currentUser.website : '',
			location: currentUser.location ? currentUser.location : '',
			interests: currentUser.interests ? currentUser.interests : '',
			phoneNum: currentUser.phoneNum ? currentUser.phoneNum : '',
			likeTaste: currentUser.likeTaste ? currentUser.likeTaste : false,
			likeTour: currentUser.likeTour ? currentUser.likeTour : false,
			likeLearn: currentUser.likeLearn ? currentUser.likeLearn : false,
			likeVolunteer: currentUser.likeVolunteer ? currentUser.likeVolunteer : false,
			likeCommunity: currentUser.likeCommunity ? currentUser.likeCommunity : false,

			name: currentUser.name ? currentUser.name : '',
			age: currentUser.age ? currentUser.age : '',
			maritalStatus: currentUser.maritalStatus ? currentUser.maritalStatus : '',
			ethnicity: currentUser.ethnicity ? currentUser.ethnicity : '',
			volunteerDetails: currentUser.volunteerDetails ? currentUser.volunteerDetails : '',
			learnDetails: currentUser.learnDetails ? currentUser.learnDetails : '',
			tourDetails: currentUser.tourDetails ? currentUser.tourDetails : '',
			tasteDetails: currentUser.tasteDetails ? currentUser.tasteDetails : '',
			likeMusicDance: currentUser.likeMusicDance ? currentUser.likeMusicDance : false,
			likeArtsCraft: currentUser.likeArtsCraft ? currentUser.likeArtsCraft : false,
			likeSocialize: currentUser.likeSocialize ? currentUser.likeSocialize : false,
			likeHistory: currentUser.likeHistory ? currentUser.likeHistory : false,
			likeGardening: currentUser.likeGardening ? currentUser.likeGardening : false,
			likeSports: currentUser.likeSports ? currentUser.likeSports : false,
			likeReading: currentUser.likeReading ? currentUser.likeReading : false,
			likeMuseums: currentUser.likeMuseums ? currentUser.likeMuseums : false,
			likeCooking: currentUser.likeCooking ? currentUser.likeCooking : false,
			likePhotography: currentUser.likePhotography ? currentUser.likePhotography : false,
			communityDetails: currentUser.communityDetails ? currentUser.communityDetails : ''
		});
	};

	render () {
		const { classes } = this.props;
		return (
			<Fragment>
				<MyButton
					tip='Edit Details'
					onClick={this.handleOpen}
					btnClassName={classes.button}
					style={{ color: '#367786' }}
				>
					<p>Edit Profile</p>
					{/*<EditIcon color="primary" />  */}
				</MyButton>
				<Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth='sm'>
					<DialogTitle>Edit your details</DialogTitle>
					<DialogContent>
						<form>
							{/* name */}
							<TextField
								name='name'
								type='text'
								label='Name'
								placeholder='Name'
								className={classes.textField}
								value={this.state.name}
								onChange={this.handleChange}
								fullWidth
							/>
							{/* age */}
							<TextField
								name='age'
								type='text'
								label='Age'
								placeholder='Age'
								className={classes.textField}
								value={this.state.age}
								onChange={this.handleChange}
								fullWidth
							/>
							{/*Editing bio info*/}
							<TextField
								name='bio'
								type='text'
								label='Bio'
								multiline
								rows='3'
								placeholder='Some information about you'
								className={classes.textField}
								value={this.state.bio}
								onChange={this.handleChange}
								fullWidth
							/>
							{/*Editing website info*/}
							<TextField
								name='website'
								type='text'
								label='Website'
								placeholder='Your personal website'
								className={classes.textField}
								value={this.state.website}
								onChange={this.handleChange}
								fullWidth
							/>
							{/*Editing location info*/}
							<TextField
								name='location'
								type='text'
								label='Location'
								placeholder='Where are you from'
								className={classes.textField}
								value={this.state.location}
								onChange={this.handleChange}
								fullWidth
							/>
							{/*Editing phone number*/}
							<TextField
								name='phoneNum'
								type='text'
								label='Phone Number'
								placeholder='Whats your phone number'
								className={classes.textField}
								value={this.state.phoneNum}
								onChange={this.handleChange}
								fullWidth
							/>

							{/* ethnicity */}
							<TextField
								name='ethnicity'
								type='text'
								label='Ethnicity'
								placeholder='Ethnicity'
								className={classes.textField}
								value={this.state.ethnicity}
								onChange={this.handleChange}
								fullWidth
							/>
							{/*Editing martial info*/}
							<TextField
								name='maritalStatus'
								type='text'
								label='Marital Status'
								placeholder='Your Marital Status'
								className={classes.textField}
								value={this.state.maritalStatus}
								onChange={this.handleChange}
								fullWidth
							/>

							{/*Editing bio info*/}

							<TextField
								name='interests'
								type='text'
								label='Interests'
								multiline
								placeholder='What are some of your interests, ex. bowling, swimming, eating'
								className={classes.textField}
								value={this.state.interests}
								onChange={this.handleChange}
								fullWidth
							/>

							<header>Interests</header>
							<FormControlLabel
								name='likeMusicDance'
								control={
									<Checkbox
										color='primary'
										checked={this.state.likeMusicDance}
										value={this.state.likeMusicDance}
										onChange={this.handleChangeCheckbox}
									/>
								}
								label={'Music & Dance'}
								labelPlacement='end'
							/>

							<FormControlLabel
								name='likeArtsCraft'
								control={
									<Checkbox
										color='primary'
										checked={this.state.likeArtsCraft}
										value={this.state.likeArtsCraft}
										onChange={this.handleChangeCheckbox}
									/>
								}
								label={'Arts & Crafts'}
								labelPlacement='end'
							/>
							<FormControlLabel
								name='likeSocialize'
								control={
									<Checkbox
										color='primary'
										checked={this.state.likeSocialize}
										value={this.state.likeSocialize}
										onChange={this.handleChangeCheckbox}
									/>
								}
								label={'Socialize'}
								labelPlacement='end'
							/>
							<FormControlLabel
								name='likeHistory'
								control={
									<Checkbox
										color='primary'
										checked={this.state.likeHistory}
										value={this.state.likeHistory}
										onChange={this.handleChangeCheckbox}
									/>
								}
								label={'History'}
								labelPlacement='end'
							/>
							<FormControlLabel
								name='likeGardening'
								control={
									<Checkbox
										color='primary'
										checked={this.state.likeGardening}
										value={this.state.likeGardening}
										onChange={this.handleChangeCheckbox}
									/>
								}
								label={'Gardening'}
								labelPlacement='end'
							/>
							<FormControlLabel
								name='likeSports'
								control={
									<Checkbox
										color='primary'
										checked={this.state.likeSports}
										value={this.state.likeSports}
										onChange={this.handleChangeCheckbox}
									/>
								}
								label={'Sports'}
								labelPlacement='end'
							/>
							<FormControlLabel
								name='likeReading'
								control={
									<Checkbox
										color='primary'
										checked={this.state.likeReading}
										value={this.state.likeReading}
										onChange={this.handleChangeCheckbox}
									/>
								}
								label={'Reading'}
								labelPlacement='end'
							/>
							<FormControlLabel
								name='likeMuseums'
								control={
									<Checkbox
										color='primary'
										checked={this.state.likeMuseums}
										value={this.state.likeMuseums}
										onChange={this.handleChangeCheckbox}
									/>
								}
								label={'Museums'}
								labelPlacement='end'
							/>
							<FormControlLabel
								name='likeCooking'
								control={
									<Checkbox
										color='primary'
										checked={this.state.likeCooking}
										value={this.state.likeCooking}
										onChange={this.handleChangeCheckbox}
									/>
								}
								label={'Cooking'}
								labelPlacement='end'
							/>
							<FormControlLabel
								name='likePhotography'
								control={
									<Checkbox
										color='primary'
										checked={this.state.likePhotography}
										value={this.state.likePhotography}
										onChange={this.handleChangeCheckbox}
									/>
								}
								label={'Photography'}
								labelPlacement='end'
							/>

							<header>Travonde Event Preferences</header>
							<FormGroup>
								<FormControlLabel
									name='likeTaste'
									control={
										<Checkbox
											color='primary'
											checked={this.state.likeTaste}
											value={this.state.likeTaste}
											onChange={this.handleChangeCheckbox}
										/>
									}
									label={'Taste'}
									labelPlacement='end'
								/>
								<TextField
									name='tasteDetails'
									type='text'
									label='Taste Details'
									placeholder='Add Any More Details about Taste'
									className={classes.textField}
									value={this.state.tasteDetails}
									onChange={this.handleChange}
									fullWidth
								/>

								<FormControlLabel
									name='likeTour'
									control={
										<Checkbox
											color='primary'
											checked={this.state.likeTour}
											value={this.state.likeTour}
											onChange={this.handleChangeCheckbox}
										/>
									}
									label={'Tour'}
									labelPlacement='end'
								/>
								<TextField
									name='tourDetails'
									type='text'
									label='Tour Details'
									placeholder='Add Any More Details about Tour'
									className={classes.textField}
									value={this.state.tourDetails}
									onChange={this.handleChange}
									fullWidth
								/>

								<FormControlLabel
									name='likeLearn'
									control={
										<Checkbox
											color='primary'
											checked={this.state.likeLearn}
											value={this.state.likeLearn}
											onChange={this.handleChangeCheckbox}
										/>
									}
									label={'Learn'}
									labelPlacement='end'
								/>
								<TextField
									name='learnDetails'
									type='text'
									label='Learn Details'
									placeholder='Add Any More Details about Learn'
									className={classes.textField}
									value={this.state.learnDetails}
									onChange={this.handleChange}
									fullWidth
								/>

								<FormControlLabel
									name='likeVolunteer'
									control={
										<Checkbox
											color='primary'
											checked={this.state.likeVolunteer}
											value={this.state.likeVolunteer}
											onChange={this.handleChangeCheckbox}
										/>
									}
									label={'Volunteer'}
									labelPlacement='end'
								/>
								<TextField
									name='volunteerDetails'
									type='text'
									label='Volunteer Details'
									placeholder='Add Any More Details about Volunteer'
									className={classes.textField}
									value={this.state.volunteerDetails}
									onChange={this.handleChange}
									fullWidth
								/>

								<FormControlLabel
									name='likeCommunity'
									control={
										<Checkbox
											color='primary'
											checked={this.state.likeCommunity}
											value={this.state.likeCommunity}
											onChange={this.handleChangeCheckbox}
										/>
									}
									label={'Community'}
									labelPlacement='end'
								/>
								<TextField
									name='communityDetails'
									type='text'
									label='Community Details'
									placeholder='Add Any More Details about Community'
									className={classes.textField}
									value={this.state.communityDetails}
									onChange={this.handleChange}
									fullWidth
								/>
							</FormGroup>
						</form>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color='primary'>
							Cancel
						</Button>
						<Button onClick={this.handleSubmit} color='primary'>
							Save
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		);
	}
}

EditProfile.propTypes = {
	editUserDetails: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired
};
//TODO: Could use mapStateToProps if we needed more information
// but as of right now we are getting currentUser information and uid from ProfilePage
// component as props
export default connect(null, { editUserDetails })(withStyles(styles)(EditProfile));
