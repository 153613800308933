import React, { Component } from 'react';

import './termsandconditions.css'


class termsandconditions extends Component {
  render() {
      return(
          <div className = 'termsandc'>
              <h1 className = 'head'>Terms and Conditions</h1>
              <p className = 'body'>
              PLEASE READ THESE TERMS AND CONDITIONS BECAUSE BY USING THIS SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED TO BE BOUND BY THESE TERMS.
            </p>

            <p className = 'body'>
This website (“Site”) is owned and operated by Travonde. These terms and conditions are a binding agreement between you and Travonde. This Site is offered to you conditioned upon your acceptance without modification of all the terms, conditions, and notices set forth below. By accessing or using this Site in any manner, you agree to be bound by this Agreement. These terms and conditions of use may be changed by Travonde in the future without further notice. Each time you enter this Site constitutes your acceptance of the new or modified terms. These terms and conditions apply exclusively to your access to, and use of, this Site and do not alter in any way the terms or conditions of any other agreement you may have with Travonde for products, services or otherwise.
</p>
<p className = 'body'>
Privacy
</p>
<p className = 'body'>
Please refer to our Privacy Policy for information about how Travonde uses and collects information. By using our Site, you consent to the use of your personal information by Travonde in accordance with the terms of the Privacy Policy.
</p>
<p className = 'body'>
Security
</p>
<p className = 'body'>
You also acknowledge that while Travonde strives to use commercially acceptable means to protect your personal information, no method of transmission over the Internet, or method of electronic storage, is 100% secure and thus Travonde cannot guarantee its absolute security. To the extent permitted by law, Travonde makes no representation or warranty with regard to the sufficiency of the security measures used for data handling and storage. Travonde assumes no liability for disclosure of personal information due to, among other things, errors in transmission, security breaches or unauthorized third parties.
</p>
<p className = 'body'>
Other Sites
</p>
<p className = 'body'>
Travonde makes no claim or representation regarding, and accepts no responsibility for, the quality, content, nature or reliability of sites accessible by hyperlink from this Site, or sites linking to this Site. The linked sites are not under the control of Travonde and Travonde is not responsible for the content of any linked site or any link contained in a linked site, or any review, changes or updates to such sites. Travonde is providing these links to you only as a convenience, and the inclusion of any link does not imply affiliation, endorsement or adoption by Travonde of the site or any information contained therein. When leaving the Travonde site, you should be aware that Travonde’s terms and policies no longer govern, and therefore you should review the applicable terms and policies, including privacy and data-gathering practices, of that site.
</p>
<p className = 'body'>
Copyright and Trademark
</p>
<p className = 'body'>
The Site and the Content contain copyrighted materials, trademarks, and other proprietary materials that are owned by Travonde, its affiliates and/or third parties (such as suppliers or licensors), and are protected by intellectual property laws of the United States and other countries. Travonde, the Travonde logo and the products and services described in this Site are either trademarks or registered trademarks of Travonde and/or its suppliers and licensors, and may not be copied, imitated or used, in whole or in part, without the prior written permission of Travonde. All other trademarks, registered trademarks, product names and company names or logos mentioned herein are the property of their respective owners.
</p>
<p className = 'body'>

You should assume that all content of the Site is copyrighted unless otherwise noted and may not be used, copied, reproduced, downloaded, modified, published, distributed, transmitted, or exploited, in whole or in part, in any form or by any means, without the express written permission of Travonde, except as provided herein. Nothing contained herein shall be construed as conferring by implication, estoppel, or otherwise any license or right, either express or implied, under any intellectual property of Travonde or any third party.
</p>
 
<p className = 'body'>
Permission is granted to display, copy, distribute and download the materials on this Site for personal, noncommercial and informational use only; provided that you may not, without the permission of Travonde or the respective copyright owner, (a) copy, publish or post any materials on any computer network or broadcast or publications media, (b) modify the materials or (c) remove or alter any copyright or other proprietary notices contained in the materials.
</p>
<p className = 'body'>
Permission is also granted for a limited, nonexclusive right to create a hyperlink to this Site provided such link does not portray Travonde or any of its products and services in a false, misleading, derogatory or otherwise defamatory manner. You may not use a Travonde logo or other proprietary graphic or trademark of Travonde to link to this Site without the express written permission of Travonde. This limited right may be revoked at any time.
</p>
<p className = 'body'>
Legal Authority and Capacity
</p>
<p className = 'body'>
By using this Site, you certify and warrant that you are the age of 18 or older and possess the capacity and legal authority to create a binding legal obligation. 
</p>
<p className = 'body'>
User Guidelines
</p>
<p className = 'body'>
By using this Site, you agree to not engage in any conduct or post content that is in Travonde’s sole judgment offensive, harmful, inappropriate or lead to an illegal activity or outcome.  You may NOT: promote illegal or inappropriate activities; post hateful or dangerous content or events by such organizations; post harmful, illegal, or explicit content; interfere with Travonde services including its servers and networks; infringe on the intellectual property rights of others; and invade the privacy of others.
</p>
<p className = 'body'>
You also agree that you are solely responsible for actions and communications undertaken or transmitted under your account, and that you will comply with all applicable local, state, national and international laws and regulations that relate to your use of or activities on this Site.
</p>
<p className = 'body'>
User Submissions
</p>


<p className = 'body'>
Travonde may invite a member to write a customer review on his or her personal experience and display the review publicly on its website and elsewhere. You acknowledge that Travonde may choose to provide attribution of your comments or reviews (for example, listing your first name and hometown on a review that you submit) at our discretion. Travonde takes no responsibility and assume no liability for any submissions submitted by you. You also agree that any materials, including but not limited to questions, comments, ratings or reviews, suggestions, ideas, plans, notes, drawings, original or creative materials or other information, provided by you in the form of email or submissions to Travonde, or postings on the Site or Travonde’s social media (such as Facebook and Twitter accounts) (collectively, "User Submissions"), are nonconfidential and shall become the sole property of Travonde. Travonde shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use of these materials for any purpose, commercial or otherwise, without acknowledgment or compensation to you. The submission of any materials to Travonde, including the posting of materials to any forum or interactive area, irrevocably waives any rights in such materials.
</p>
<p className = 'body'>
The information and opinions expressed in User Submissions on this Site are not necessarily those of Travonde or its content providers, advertisers, sponsors, affiliated or related entities, and Travonde makes no representations or warranties regarding any User Submission. Travonde does not represent or guarantee the truthfulness, accuracy, or reliability of any User Submissions or determine whether the User Submissions violate the rights of others. You acknowledge that any reliance on material posted by other Users will be at your own risk.
</p>
<p className = 'body'>
You further agree that you will be solely responsible for the content of any User Submissions you make and that you shall be solely liable for any harm resulting from your User Submission.
</p>
<p className = 'body'>
Supplier Terms and Conditions
</p>
<p className = 'body'>
You agree to abide by the terms and conditions of purchase imposed by any supplier with whom you elect to deal, including, but not limited to, payment of all amounts when due and compliance with the supplier’s rules and restrictions regarding availability and use of products or services.  You acknowledge and agree that services and products are provided by and are the sole responsibility of each individual supplier and that Travonde is not and shall not be held responsible or liable for these services and products.
</p>
<p className = 'body'>

Indemnification
</p>
<p className = 'body'>
You agree to indemnify and hold harmless Travonde and the Covered Parties (defined below in the Limitations on Liability section) from and against any claims, causes of action, demands, losses, fines, penalties, damages or other costs or expenses of any kind or nature (including attorneys’ fees, expert fees and accounting fees) made or incurred or brought by any third party arising out of or relating to (i) your conduct, (ii) your use of this Site, (iii) your breach of this Agreement or the documents referenced herein, or (iv) your violation of any law or the rights of a third party.
</p>
 
<p className = 'body'>
Disclaimer of Warranties
</p>
<p className = 'body'>
YOU UNDERSTAND AND AGREE THAT THIS SITE AND THE INFORMATION, SERVICES, PRODUCTS AND MATERIALS AVAILABLE THROUGH IT ARE PROVIDED ON AN "AS-IS" AND "AS AVAILABLE" BASIS. YOU EXPRESSLY AGREE THAT USE OF THIS SITE IS AT YOUR SOLE RISK.
</p>
 
<p className = 'body'>
TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, TRAVONDE AND ITS AFFILIATES DISCLAIM ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF TITLE, OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. NO ORAL OR WRITTEN INFORMATION PROVIDED BY TRAVONDE OR ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PROVIDERS, MERCHANTS, SPONSORS, LICENSORS OR THE LIKE SHALL CREATE A WARRANTY; NOR SHALL YOU RELY ON ANY SUCH INFORMATION OR ADVICE.
</p>
 
<p className = 'body'>
YOU EXPRESSLY AGREE THAT USE OF THIS SITE, INCLUDING ALL CONTENT, DATA OR SOFTWARE DISTRIBUTED BY, DOWNLOADED OR ACCESSED FROM OR THROUGH THIS SITE, IS AT YOUR SOLE RISK. YOU UNDERSTAND AND AGREE THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR BUSINESS OR YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH CONTENT, DATA AND/OR SOFTWARE.
</p>
 
<p className = 'body'>
YOU ACKNOWLEDGE THAT TRAVONDE DOES NOT CONTROL IN ANY RESPECT ANY INFORMATION, PRODUCTS OR SERVICES OFFERED BY THIRD PARTIES ON OR THROUGH THIS SITE. EXCEPT AS OTHERWISE AGREED IN WRITING, TRAVONDE AND ITS AFFILIATES ASSUME NO RESPONSIBILITY FOR AND MAKE NO WARRANTY OR REPRESENTATION AS TO THE ACCURACY, CURRENCY, COMPLETENESS, RELIABILITY OR USEFULNESS OF CONTENT OR PRODUCTS DISTRIBUTED OR MADE AVAILABLE BY THIRD PARTIES THROUGH THIS SITE. NO WARRANTY OR GUARANTEE IS MADE THAT A USER WILL RECEIVE THE LOWEST AVAILABLE PRICE FOR GOODS AND/OR SERVICES AVAILABLE THROUGH THIS SITE. PRICES AND AVAILABILITY ARE SUBJECT TO CHANGE AT ANY TIME PRIOR TO PURCHASE. TRAVONDE DISCLAIMS ALL LIABILITY FOR ANY ERRORS OR OTHER INACCURACIES RELATING TO THE INFORMATION AND DESCRIPTION OF ANY TRAVEL PRODUCTS DISPLAYED ON THIS SITE (INCLUDING, WITHOUT LIMITATION, THE PRICING, PHOTOGRAPHS, LIST OF HOTEL AMENITIES, GENERAL PRODUCT DESCRIPTIONS, ETC.). IN ADDITION, TRAVONDE EXPRESSLY RESERVES THE RIGHT TO UPDATE PRICES AT ANY TIME AND/OR CORRECT ANY PRICING ERRORS ON OUR SITE AND/OR ON PENDING RESERVATIONS MADE UNDER AN INCORRECT PRICE.
</p>
 
<p className = 'body'>
TRAVONDE DOES NOT MAKE ANY WARRANTY THAT THIS SITE OR ITS CONTENT WILL MEET YOUR REQUIREMENTS, OR THAT THE SITE OR CONTENT WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR FREE, OR THAT DEFECTS, IF ANY, WILL BE CORRECTED. TRAVONDE DOES NOT REPRESENT OR WARRANT THAT MATERIALS IN THIS SITE OR INFORMATION PROVIDED BY TRAVONDE VIA EMAIL OR OTHER MEANS ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. NOR DOES TRAVONDE MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF TRAVONDE OR ITS CONTENT OR AS TO THE ACCURACY, COMPLETENESS OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH USE OF THE SITE.
</p>
 
<p className = 'body'>
TRAVONDE ASSUMES NO RESPONSIBILITY FOR ANY DAMAGES SUFFERED BY A USER, INCLUDING, BUT NOT LIMITED TO, LOSS OF DATA FROM DELAYS, NONDELIVERIES OF CONTENT OR EMAIL, ERRORS, SYSTEM DOWN TIME, MISDELIVERIES OF CONTENT OR EMAIL, NETWORK OR SYSTEM OUTAGES, FILE CORRUPTION OR SERVICE INTERRUPTIONS CAUSED BY THE NEGLIGENCE OF TRAVONDE, ITS AFFILIATES, ITS LICENSORS OR A USER’S OWN ERRORS AND/OR OMISSIONS.
</p>
 
<p className = 'body'>
TRAVONDE ASSUMES NO RESPONSIBILITY FOR ANY DAMAGES SUFFERED BY A USER, INCLUDING, BUT NOT LIMITED TO, LOSS FROM NONDELIVERY OF SERVICES PURCHASED FROM COMPANIES LISTED ON TRAVONDE SUCH AS (BUT NOT LIMITED TO) TOURS, AIRFARE, HOTELS, TRANSFERS AND VOUCHERS. USER IS AWARE THAT COMPANIES AND THEIR AGENTS PROVIDING SUCH SERVICES ARE NOT IN ANY WAY RELATED TO TRAVONDE AND TRAVONDE HAS NO RESPONSIBILITY TO ENSURE DELIVERY OF SUCH SERVICES.
</p>
 
<p className = 'body'>
TRAVONDE DISCLAIMS ANY WARRANTY OR REPRESENTATION THAT CONFIDENTIALITY OF INFORMATION TRANSMITTED THROUGH THIS WEBSITE WILL BE MAINTAINED.
</p>
 
<p className = 'body'>
SOME STATES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF CERTAIN WARRANTIES OR CONDITIONS, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
</p>
 
<p className = 'body'>
Limitation on Liability
</p>
<p className = 'body'>
UNDER NO CIRCUMSTANCES, INCLUDING WITHOUT LIMITATION NEGLIGENCE, SHALL TRAVONDE OR ITS PARENTS, SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS OR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, TRANSMITTING, OR DISTRIBUTING TRAVONDE CONTENT OR RELATED SERVICES (COLLECTIVELY THE "COVERED PARTIES") BE LIABLE TO ANY PERSON OR ENTITY WHATSOEVER FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, COMPENSATORY, PUNITIVE OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER ARISING FROM OR IN CONNECTION WITH THE USE OR INABILITY TO USE THE SITE OR ANY CONTENT PROVIDED BY OR THROUGH THIS SITE OR ANY PRODUCTS OR SERVICES OBTAINED THROUGH THE SITE OR RESULTING FROM UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, OR OTHER INFORMATION THAT IS SENT OR RECEIVED, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOST PROFITS, USE, DATA OR OTHER INTANGIBLES. THE LIMITATIONS OF LIABILITY SHALL APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF A COVERED PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

</p>
<p className = 'body'>
FURTHER, THE SUPPLIERS AND/OR SERVICE PROVIDERS ON THE SITE ARE INDEPENDENT CONTRACTORS AND NOT AGENTS OR EMPLOYEES OF THE COVERED PARTIES. TO THE EXTENT PERMITTED BY LAW, THE COVERED PARTIES DO NOT ASSUME LIABILITY FOR ANY INJURY, DAMAGE, DEATH, PROPERTY DAMAGE, LOSS, ACCIDENT OR DELAY DUE TO AN ACT, ERROR OR OMISSION OF A SERVICE PROVIDER, INCLUDING, WITHOUT LIMITATION, AN ACT OF NEGLIGENCE OR THE DEFAULT OF A SERVICE PROVIDER, OR AN ACT OF GOD. THE COVERED PARTIES SHALL HAVE NO LIABILITY AND WILL MAKE NO REFUND IN THE EVENT OF ANY DELAY, CANCELLATION, OVERBOOKING, STRIKE, FORCE MAJEURE OR OTHER CAUSES BEYOND THEIR DIRECT CONTROL, AND THEY HAVE NO RESPONSIBILITY FOR ANY ADDITIONAL EXPENSE, OMISSIONS, DELAYS, RE-ROUTING OR ACTS OF ANY GOVERNMENT OR AUTHORITY.
</p>
 
<p className = 'body'>
IF, NOTWITHSTANDING THE ABOVE, A COVERED PARTY IS FOUND LIABLE FOR ANY LOSS OR DAMAGE RELATING TO THE USE OF THIS SITE, USER AGREES THE LIABILITY OF ANY SUCH PARTY SHALL IN NO EVENT EXCEED THE TRANSACTION FEES ASSESSED BY TRAVONDE FOR YOUR TRANSACTION ON THIS SITE. THIS LIMITATION OF LIABILITY REFLECTS THE ALLOCATION OF RISK BETWEEN THE PARTIES.

</p>
<p className = 'body'>
Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so some of the above may not apply to you. In such jurisdictions, liability is limited to the fullest extent permitted by law. In addition, to the extent allowed by applicable law, you agree that you will bring any claim or cause of action arising from or relating to your access or use of this Site within two (2) years from the date on which such claim or action arose or accrued or such claim or cause of action will be irrevocably waived.
</p>
 
<p className = 'body'>
Travel Destinations
</p>
<p className = 'body'>
You are responsible for ensuring that you meet entry requirements and that you hold any necessary travel documents, such as identification, passports and visas, and any other entry requirements are met. You are also responsible for requesting or notifying Travonde and/or suppliers of any individual accommodations or accessibility needs. Travel to certain destinations may involve greater risk than others and it is your sole responsibility to be learn and prepare for any such risks. Travonde shall not be liable for damages or losses that may result from travel to such destinations, even if the destination is listed on Travonde’s or its suppliers’ sites.  
</p>
 
<p className = 'body'>
Arbitration and Applicable Law
</p>
<p className = 'body'>
To the extent permitted by law, this Agreement and the provision of our services shall be governed by and construed in accordance with California law. You agree that any dispute, claim, or controversy between you and Travonde arising in connection with or relating in any way to this Agreement or to your relationship with Travonde (whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and whether the claims arise during or after the termination of the Agreements) will be determined by mandatory binding individual (not class) arbitration. You further agree that the arbitrator shall have the exclusive power to rule on his or her own jurisdiction, including any objections with respect to the existence, scope or validity of the Arbitration Agreement or to the arbitrability of any claim or counterclaim. Arbitration is more informal than a lawsuit in court. THERE IS NO JUDGE OR JURY IN ARBITRATION, AND COURT REVIEW OF AN ARBITRATION AWARD IS LIMITED. There may be more limited discovery than in court. The arbitrator must follow this agreement and can award the same damages and relief as a court (including attorney fees), except that the arbitrator may not award any relief, including declaratory or injunctive relief, benefiting anyone but the parties to the arbitration. This arbitration provision will survive termination of other Agreements.
</p>
 
<p className = 'body'>
Any arbitration between you and Travonde will take place under the Consumer Arbitration Rules of the American Arbitration Association (“AAA”) then in force (the “AAA Rules”), as modified by this Arbitration Agreement. You agree that the Federal Arbitration Act applies and governs the interpretation and enforcement of this provision (despite the choice of law provision above). The AAA Rules, as well as instructions on how to file an arbitration proceeding with the AAA, appear at adr.org, or you may call the AAA at 1-800-778-7879.
</p>
 
<p className = 'body'>
You may choose to have an arbitration conducted by telephone, based on written submissions, or in person in the state where you live or at another mutually agreed location. To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claims to Travonde at 5339 Prospect Road Suite 200, San Jose, CA 95129. If Travonde requests arbitration against you, we will give you notice at the email address or street address you have provided.

</p>
<p className = 'body'>
Termination
</p>
<p className = 'body'>
Notwithstanding any of these terms and conditions, Travonde reserves the right, without notice and in its sole discretion, for any reason whatsoever to block or prevent access to and use of this Site. Travonde shall not be liable to you or any third-party for any termination of your access to this Site.
</p>
 
<p className = 'body'>
Severability
</p>
<p className = 'body'>
If any provision of these terms and conditions shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these terms and conditions and shall not affect the validity and enforceability of any remaining provisions.
</p>
 
<p className = 'body'>
Contact Us
</p>
<p className = 'body'>
If you have any questions concerning these Terms and Conditions, please contact Travonde at 5339 Prospect Road Suite 200, San Jose, CA 95129. 
</p>
          </div>
      )
    }
}

export default termsandconditions