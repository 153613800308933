import { SET_EVENT_ID } from '../../../redux/types';

const initialState = {
	eventId: '',
	relatedEvents: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_EVENT_ID:
			return {
				...state,
				eventId: action.payload
			};
		default:
			return state;
	}
}
