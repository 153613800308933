import React, { Component, Fragment } from 'react';
import firebase, { storage } from './Firebase/firebase'
import MyButton from '../util/MyButton';

//Redux

import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";


import Button from "@material-ui/core/Button";

import {Row} from 'antd'

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      url: '',
      open: false,
      file: null
    }
    this.handleChange = this
      .handleChange
      .bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  handleChange = e => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
      this.setState({ file: URL.createObjectURL(image) })
    }
  }

  handleUpload = () => {
    const { image } = this.state;
    const uploadTask = storage.ref(`users/${this.props.userID}/${image.name}`).put(image);
    uploadTask.on('state_changed',
      (snapshot) => {
      },
      (error) => {
        // error function ....
        console.log(error);
      },
      () => {
        // complete function ....
        storage.ref(`users/${this.props.userID}`).child(image.name).getDownloadURL().then(url => {
          firebase.firestore().collection('users').doc(`${this.props.userID}`).set({
            imageUrl: url
          }, { merge: true });
          console.log(url);
          this.setState({ url });
        })
      });
    this.setState({ open: false })
  }

  handleOpen = () => {
    this.setState({
      open: true
    });
    //this.mapUserDetailsToState(this.props.currentUser);
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {

    return (

      <Fragment>
        <MyButton
          tip="Update Photo"
          onClick={this.handleOpen}
          //btnClassName={classes.button}
          style={{ color: "#367786" }}
        >
          <p>Update Photo</p>
          {/*<EditIcon color="primary" />  */}
        </MyButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Update your Profile Picture</DialogTitle>
          <DialogContent>
            <div>
              <br />
              <Row >
              {this.state.file ?
                (<Avatar size={128} src={this.state.file} shape={"circle"} />
                ) : (
                  <Avatar size={128} icon={<UserOutlined />} shape={"circle"} />)}
              </Row>
                  <br/>
              <Row >
              <input type="file" onChange={this.handleChange} />
              </Row>

              <br />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
                </Button>
            <Button onClick={this.handleUpload} color="primary">
              Save
                </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    )
  }
}

export default ImageUpload;