import React, { Fragment } from 'react';
//import 'semantic-ui-css/semantic.min.css';
import { Icon } from 'semantic-ui-react';
import './EventAmenities.css';
import { Row, Col } from 'react-flexbox-grid';

//TODO
// Change Pet Friendly -> Service Dog
//	serviceDogsAllowed
// if Online only have the wifi icon with Online text
const EventAmenities = ({ event }) => {
	if (event.online) {
		return (
			<Fragment>
				<div>
					<h2>Amenities</h2>
					<Col xs sm={6}>
						<div>
							<Icon name='wi-fi' /> Online
						</div>
					</Col>
				</div>
			</Fragment>
		);
	}
	return (
		event && (
			<Fragment>
				<div>
					<h2>Amenities</h2>
					<Row>
						<Col xs sm={6}>
							<div>
								<Icon name='wi-fi' /> Wifi
							</div>
							{event.dropOffSpot ? (
								<div>
									<Icon name='map marker alternate' /> Drop Off Spot
								</div>
							) : (
								<div>
									<strike>
										<Icon name='map marker alternate' /> Drop Off Spot
									</strike>
								</div>
							)}
							{event.serviceDogsAllowed ? (
								<div>
									<Icon name='paw' /> Service Dogs
								</div>
							) : (
								<div>
									<strike>
										<Icon name='paw' /> Service Dogs
									</strike>
								</div>
							)}
							{/* <div style="margin-left:200px;"> */}
							<div>
								{event.wheelchair ? (
									<div>
										<Icon name='wheelchair' /> Wheelchair Accessible
									</div>
								) : (
									<div>
										<strike>
											<Icon name='wheelchair' /> Wheelchair Accessible
										</strike>
									</div>
								)}
							</div>
							<div>
								<Icon name='car' /> Easy Parking
							</div>
							<div>
								{event.guardrails ? (
									<div>
										<Icon name='bars' /> Guardrails
									</div>
								) : (
									<div>
										<strike>
											<Icon name='bars' /> Guardrails
										</strike>
									</div>
								)}
							</div>
							<div>
								{event.pavedTrail ? (
									<div>
										<Icon name='angle double up' /> Paved Trail
									</div>
								) : (
									<div>
										<strike>
											<Icon name='angle double up' /> Paved Trail
										</strike>
									</div>
								)}
							</div>
							{event.walkingSteps ? (
								<div>
									<Icon name='map outline' /> Walking Steps: {`${event.walkingSteps}`}
								</div>
							) : (
								<div>
									<Icon name='map outline' /> Walking Steps: N/A
								</div>
							)}
						</Col>
						<Col>
							<div>
								{event.restrooms ? (
									<div>
										<Icon name='bath' /> Restrooms
									</div>
								) : (
									<div>
										<strike>
											<Icon name='bath' /> Restrooms
										</strike>
									</div>
								)}
							</div>
							<div>
								{event.familyRestrooms ? (
									<div>
										<Icon name='bath' /> Family Restrooms
									</div>
								) : (
									<div>
										<strike>
											<Icon name='bath' /> Family Restrooms
										</strike>
									</div>
								)}
							</div>
							<div>
								{event.evelavtors ? (
									<div>
										<Icon name='building' /> Elevators
									</div>
								) : (
									<div>
										<strike>
											<Icon name='building' /> Elevators
										</strike>
									</div>
								)}
							</div>
							<div>
								{event.handicapParking ? (
									<div>
										<Icon name='map pin' /> Handicap Parking
									</div>
								) : (
									<div>
										<strike>
											<Icon name='map pin' /> Handicap Parking
										</strike>
									</div>
								)}
							</div>
							{/* <div>
							{event.dropOffSpot ? (
								<div>
									<Icon name='bath' /> Drop Off Spot
								</div>
							) : (
								<div>
									<strike>
										<Icon name='bath' /> Drop Off Spot
									</strike>
								</div>
							)}
						</div> */}
							<div>
								<div className='Covid-19'>
									<Icon name='hospital outline' /> COVID-19 Response
								</div>
								<p className='covid'>
									Masks required <br /> 6 feet apart <br />
									Place cleaned daily
								</p>
							</div>
						</Col>
					</Row>
					{/* </div> */}
				</div>
			</Fragment>
		)
	);
};

export default EventAmenities;
