import React, { Component, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logoutUser } from '../pages/UserProfile/userActions';
import './Navbar.css';
import Flex from './Flex';
import { HamburgerSpring } from 'react-animated-burgers';

class Navbar extends Component {
	constructor () {
		super();
		this.state = {
			opened: false,
			windowWidth: window.innerWidth
		};
	}
	handleResize = (e) => {
		this.setState({ windowWidth: window.innerWidth });
	};

	componentDidMount = () => {
		window.addEventListener('resize', this.handleResize);
	};

	componentWillUnMount = () => {
		window.addEventListener('resize', this.handleResize);
	};

	toggle = () => {
		this.setState({
			opened: !this.state.opened
		});
	};

	render () {
		const { authenticated } = this.props;
		const { windowWidth, opened } = this.state;
		const partnerStyle = { color: 'white', fontSize: '16px' };
		return (
			<div>
				<nav className='Navbar'>
					<div className='navbar-home'>
						<Link to={'/'}>
							<div className='logo'>
								<img src={require('./TRAVONDE_WHITE.png')} alt='travonde' />
							</div>
						</Link>
						{/* <button className='toggle' onClick={this.toggle.bind(this)}>
							<i className={'fas ' + (this.state.opened ? 'fa-angle-up' : 'fa-angle-down')} />
						</button> */}
						{windowWidth <= 480 &&
						windowWidth >= 350 && (
							<Flex
								flexDirection={'column'}
								justifyContent={'center'}
								alignItems={'center'}
								style={{ marginRight: windowWidth > 360 ? '-15px' : '-25px', marginTop: '-5px' }}
							>
								<Flex flexDirection={'column'} justifyContent={'center'}>
									<HamburgerSpring isActive={opened} toggleButton={this.toggle} barColor='white' />
								</Flex>
								{windowWidth > 330 && (
									<Flex
										flexDirection={'column'}
										justifyContent={'center'}
										style={{ marginTop: '-20px' }}
									>
										<span onClick={this.toggle} style={{ color: 'white', fontSize: '15px' }}>
											Menu
										</span>
									</Flex>
								)}
							</Flex>
						)}
					</div>

					{windowWidth > 480 ? (
						<ul className={'navbar-links ' + (this.state.opened ? 'opened' : 'closed')}>
							{authenticated ? (
								<Fragment>
									<li className='navbar-link'>
										<Link className='nav-links' to={'/'} id='tab'>
											Home
										</Link>
									</li>
									<li className='navbar-link'>
										<Link className='nav-links' to={'/about'} id='tab'>
											About
										</Link>
									</li>

									<li className='navbar-link'>
										<a
											href={
												'https://docs.google.com/forms/d/e/1FAIpQLSdKLQxj6HaxaKjBPu3s31BdFr_-RMA2lc-wKTUngEVszEvhJg/viewform'
											}
											target='_blank'
											rel='noopener noreferrer'
											style={partnerStyle}
										>
											Partner
										</a>
									</li>

									<li className='navbar-link'>
										<Link className='nav-links' to={'/profile'}>
											Profile
										</Link>
									</li>
								</Fragment>
							) : (
								<Fragment>
									<li className='navbar-link'>
										<Link className='nav-links' to={'/'} id='tab'>
											Home
										</Link>
									</li>
									<li className='navbar-link'>
										<Link className='nav-links' to={'/about'} id='tab'>
											About
										</Link>
									</li>

									<li className='navbar-link'>
										<a
											href={
												'https://docs.google.com/forms/d/e/1FAIpQLSdKLQxj6HaxaKjBPu3s31BdFr_-RMA2lc-wKTUngEVszEvhJg/viewform'
											}
											target='_blank'
											rel='noopener noreferrer'
											style={partnerStyle}
										>
											Partner
										</a>
									</li>

									<li className='navbar-link'>
										<Link className='nav-links' to={'/signup'} id='tab'>
											Sign Up
										</Link>
									</li>
									<li className='navbar-link'>
										<Link className='nav-links' to={'/login'} id='tab'>
											Login
										</Link>
									</li>
								</Fragment>
							)}
						</ul>
					) : (
						<Fragment>
							{windowWidth <= 330 && (
								<Flex justifyContent={'flex-end'} style={{ marginTop: '25px' }}>
									<HamburgerSpring isActive={opened} toggleButton={this.toggle} barColor='white' />
								</Flex>
							)}
							{opened && (
								<ul className={'navbar-links ' + (this.state.opened ? 'opened' : 'closed')}>
									{authenticated ? (
										<Fragment>
											<li className='navbar-link'>
												<Link className='nav-links' to={'/'} id='tab'>
													Home
												</Link>
											</li>
											<li className='navbar-link'>
												<Link className='nav-links' to={'/about'} id='tab'>
													About
												</Link>
											</li>

											<li className='navbar-link'>
												<a
													href={
														'https://docs.google.com/forms/d/e/1FAIpQLSdKLQxj6HaxaKjBPu3s31BdFr_-RMA2lc-wKTUngEVszEvhJg/viewform'
													}
													target='_blank'
													rel='noopener noreferrer'
													style={partnerStyle}
												>
													Partner
												</a>
											</li>

											<li className='navbar-link'>
												<Link className='nav-links' to={'/profile'}>
													Profile
												</Link>
											</li>
										</Fragment>
									) : (
										<Fragment>
											<li className='navbar-link'>
												<Link className='nav-links' to={'/'} id='tab'>
													Home
												</Link>
											</li>
											<li className='navbar-link'>
												<Link className='nav-links' to={'/about'} id='tab'>
													About
												</Link>
											</li>

											<li className='navbar-link'>
												<a
													href={
														'https://docs.google.com/forms/d/e/1FAIpQLSdKLQxj6HaxaKjBPu3s31BdFr_-RMA2lc-wKTUngEVszEvhJg/viewform'
													}
													target='_blank'
													rel='noopener noreferrer'
													style={partnerStyle}
												>
													Partner
												</a>
											</li>

											<li className='navbar-link'>
												<Link className='nav-links' to={'/signup'} id='tab'>
													Sign Up
												</Link>
											</li>
											<li className='navbar-link'>
												<Link className='nav-links' to={'/login'} id='tab'>
													Login
												</Link>
											</li>
										</Fragment>
									)}
								</ul>
							)}
						</Fragment>
					)}
				</nav>
			</div>
		);
	}
}

Navbar.propTypes = {
	authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
	authenticated: state.user.authenticated
});

const mapDispatchToProps = {
	logoutUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
