import {
	SET_EVENTS,
	LOADING_DATA,
	SET_EVENT,
	CLEAR_ERRORS,
	SET_RELATED_EVENTS,
	SET_ERRORS,
	SET_FEATURED_EVENTS,
	SET_INTERESTED_EVENTS,
	SET_INTERESTED_EVENTS_FAIL,
	SET_CITIES,
	SET_CITIES_FAIL,
	SET_FEATURED_EVENTS_ORDER_SUCCESS,
	SET_FEATURED_EVENTS_ORDER_FAIL,
	SET_FEATURED_VIDEOS_SUCCESS,
	SET_FEATURED_VIDEOS_FAIL,
	SET_ANNOUNCEMENTS_SUCCESS,
	SET_ANNOUNCEMENTS_FAIL
} from '../../redux/types';
import firebase from '../../components/Firebase/firebase';

export const getFilteredEvents = (city, dateFilter, endDate, minPrice1, maxPrice1) => async (dispatch, getState) => {
	//var date = new Date();

	//dayOfWeek will be neg if day of week is not going to be used
	//var dayOfWeek = -1;
	// if (!endDate) {
	// 	dayOfWeek = Number(dateFilter);
	// }
	await firebase.firestore().collection('Events').get().then((doc) => {
		if (doc.size) {
			let events = [];
			doc.forEach((doc) => {
				events.push({ ...doc.data(), uid: doc.id });
			});
			dispatch({
				type: SET_EVENTS,
				payload: events
			});
		} else {
			dispatch({
				type: SET_EVENTS,
				payload: []
			});
		}
	});
};

export const getAnnouncements = () => async (dispatch) => {
	await firebase
		.firestore()
		.collection('Announcements')
		.doc('Df2ICy5cN0au9vaB2BNN')
		.get()
		.then((doc) => {
			if (doc.exists) {
				dispatch({
					type: SET_ANNOUNCEMENTS_SUCCESS,
					payload: doc.data()
				});
			} else {
				console.log('Doc does not exist');
			}
		})
		.catch((error) => {
			console.error(error);
			dispatch({ type: SET_ANNOUNCEMENTS_FAIL });
		});
};

export const getFeaturedEvents = () => {
	return async (dispatch) => {
		dispatch({ type: LOADING_DATA });
		try {
			let events = [];

			await firebase.firestore().collection('FeaturedEvents').get().then((snapshot) => {
				if (snapshot.size) {
					//TODO no need to get related events until into event page
					snapshot.forEach((doc) => {
						// let relatedEvents = [];
						// firebase
						// 	.firestore()
						// 	.collection('TestRelatedEvents')
						// 	.doc(doc.id)
						// 	.collection('Related')
						// 	.get()
						// 	.then((snapshot) => {
						// 		if (snapshot.size) {
						// 			snapshot.forEach((relatedDoc) =>
						// 				relatedEvents.push({ ...relatedDoc.data(), uid: relatedDoc.id })
						// 			);
						// 		}
						// 	});
						events.push({ ...doc.data(), uid: doc.id }); //, relatedEvents: relatedEvents });
					});
					dispatch({
						type: SET_FEATURED_EVENTS,
						payload: events
					});
				} else {
					dispatch({
						type: SET_FEATURED_EVENTS,
						payload: []
					});
				}
			});
		} catch (error) {
			dispatch({
				type: SET_ERRORS,
				errors: error
			});
		}
	};
};

export const getFeaturedEventsOrdering = () => {
	return async (dispatch) => {
		dispatch({ type: LOADING_DATA });

		await firebase
			.firestore()
			.collection('FeaturedEventsOrdering')
			.doc('ordering')
			.get()
			.then((doc) => {
				if (doc.exists) {
					dispatch({
						type: SET_FEATURED_EVENTS_ORDER_SUCCESS,
						payload: doc.data()
					});
				} else {
					console.log('Doc does not exist');
				}
			})
			.catch((error) => {
				console.error(error);
				dispatch({ type: SET_FEATURED_EVENTS_ORDER_FAIL });
			});
	};
};

export const getFeaturedVideos = () => {
	return async (dispatch) => {
		dispatch({ type: LOADING_DATA });
		let events = [];
		await firebase
			.firestore()
			.collection('FeaturedVideos')
			.get()
			.then((snapshot) => {
				if (snapshot.size) {
					snapshot.forEach((doc) => events.push({ ...doc.data(), uid: doc.id }));
					dispatch({
						type: SET_FEATURED_VIDEOS_SUCCESS,
						payload: events
					});
				} else {
					console.log('No events in Featured Videos');
					dispatch({
						type: SET_FEATURED_VIDEOS_SUCCESS,
						payload: []
					});
				}
			})
			.catch((error) => {
				console.error(error);
				dispatch({ type: SET_FEATURED_VIDEOS_FAIL });
			});
	};
};

export const getCategoryEvents = (eventType) => (dispatch) => {
	dispatch({ type: LOADING_DATA });
	let events = [];
	firebase
		.firestore()
		.collection('Events')
		.where('eventTypeFilter', '==', `${eventType}`)
		.get()
		.then((snapshot) => {
			if (snapshot.size) {
				snapshot.forEach((doc) => events.push({ ...doc.data(), uid: doc.id }));
				dispatch({
					type: SET_EVENTS,
					payload: events
				});
			} else {
				dispatch({
					type: SET_EVENTS,
					payload: []
				});
			}
		})
		.catch((e) => console.error(e));
};

export const getEvent = (eventID) => (dispatch, getState) => {
	dispatch({ type: LOADING_DATA });
	//let event = {};
	//const state = getState();
	firebase.firestore().collection('Events').doc(`${eventID}`).get().then((doc) => {
		if (doc) {
			dispatch({
				type: SET_EVENT,
				payload: doc.data()
			});
		} else {
			dispatch({
				type: SET_EVENT,
				payload: {}
			});
		}
	});
};

export const getEvents = (eventIds) => (dispatch) => {
	const eventIdArray = [];
	for (const eventId of eventIds) {
		eventIdArray.push(firebase.firestore().collection('ShortEvents').doc(eventId).get());
	}

	Promise.all(eventIdArray)
		.then((docArray) => {
			const events = [];
			docArray.forEach((doc) => {
				events.push({ ...doc.data(), uid: doc.id });
			});
			dispatch({
				type: SET_INTERESTED_EVENTS,
				payload: events
			});
		})
		.catch((e) => {
			console.error(e);
			dispatch({
				type: SET_INTERESTED_EVENTS_FAIL,
				payload: []
			});
		});
};

export const getRelatedEvents = (eventID) => (dispatch) => {
	dispatch({ type: LOADING_DATA });
	let relatedEvents = [];
	firebase.firestore().collection('RelatedEvents').doc(`${eventID}`).collection('Related').get().then((snapshot) => {
		if (snapshot.size) {
			snapshot.forEach((doc) => relatedEvents.push({ ...doc.data(), uid: doc.id }));
			dispatch({
				type: SET_RELATED_EVENTS,
				payload: relatedEvents
			});
			return relatedEvents;
		} else {
			dispatch({
				type: SET_RELATED_EVENTS,
				payload: []
			});
		}
	});
};

export const getCities = () => (dispatch) => {
	const cityOptions = [];
	firebase.firestore().collection('Cities').get().then((docs) => {
		if (docs.size) {
			docs.forEach((doc) => cityOptions.push(doc.id));
			dispatch({
				type: SET_CITIES,
				payload: cityOptions
			});
		} else {
			dispatch({
				type: SET_CITIES_FAIL,
				payload: []
			});
		}
	});
};

export const clearErrors = () => (dispatch, getState) => {
	dispatch({ type: CLEAR_ERRORS });
};
