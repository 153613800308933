import React from 'react';

import './Footer.css';

import { Row, Col } from 'antd';

import LocationOnIcon from '@material-ui/icons/LocationOn';

import MailIcon from '@material-ui/icons/Mail';

import fb from './FooterPictures/facebook (1).png';
import twitter from './FooterPictures/twitter (1).png';
import insta from './FooterPictures/instagram (1).png';
import youtube from './FooterPictures/youtube_footer_icon.png';
//import pin from "./FooterPictures/pinterest.png";

const ADDRESS = '5273 Prospect Road Suite 200'
const CITY_AND_ZIP_CODE = 'San Jose, CA 95129'

class Footer extends React.Component {
	constructor () {
		super();
		this.state = {
			windowWidth: window.innerWidth
		};
	}

	handleWindowResize = () => {
		this.setState({ windowWidth: window.innerWidth });
	};

	componentDidMount () {
		this.handleWindowResize();
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount () {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	render () {
		const { windowWidth } = this.state;
		//const showItems = isMobile ? 'mobile' : 'regular';

		return windowWidth > 480 ? (
			<div className='footer'>
				<br />
				<div className='footerText'>
					<Row>
						<Col span={12}>
							<p className='loc'>
								<LocationOnIcon />
								{ADDRESS}
								<br />
								{CITY_AND_ZIP_CODE}
							</p>

							<br />
							<p className='email'>
								<a className='tandc' href='mailto: info@travonde.com'>
									{' '}
									<MailIcon /> info@travonde.com
								</a>
							</p>
						</Col>

						<Col span={11}>
							<h1 className='aboutheader'>About the company</h1>
							<p className='about'>
								We started Travonde because we saw a need for our own parents to enjoy what their
								community has to offer. And we wanted to connect local businesses to people who might
								not be aware of their services. It's a great match.
							</p>

							<div className='sns'>
								<a href='https://www.facebook.com/TravondeCompany'>
									<button>
										<img className='snsbutton' src={fb} alt='facebook' />
									</button>
								</a>
								<a href='https://twitter.com/travondei'>
									<button>
										<img className='snsbutton' src={twitter} alt='twitter' />
									</button>
								</a>
								<a href='https://www.instagram.com/travonde/?hl=en'>
									<button>
										<img className='snsbutton' src={insta} alt='instagram' />
									</button>
								</a>

								<a href='https://www.youtube.com/channel/UCwIvHU5CVYZAse9aEqNG_Tw'>
									<button>
										<img className='snsbutton' src={youtube} alt='youtube' />
									</button>
								</a>
								{/* <a href = "https://www.pinterest.com">
                                    <button>
                                        <img className = 'snsbutton' src = {pin} alt =""/>
                                    </button>
                                </a> */}
							</div>
						</Col>
					</Row>
					<br />
					<p className='copyright'>
						&copy; Travonde, Inc. All rights reserved.{' '}
						<a className='tandc' href='./termsandconditions'>
							Terms
						</a>{' '}
						- Privacy - Sitemap
					</p>
				</div>
			</div>
		) : (
			<div className='footer'>
				<br />
				<div className='footerText'>
					<Row>
						<Col span={24}>
							<p className='loc'>
								<LocationOnIcon />
								{ADDRESS}
								<br />
								{CITY_AND_ZIP_CODE}
							</p>

							<br />
							<p className='email'>
								<a className='tandc' href='mailto: info@travonde.com'>
									{' '}
									<MailIcon /> info@travonde.com
								</a>
							</p>

							<div className='sns'>
								<a href='https://www.facebook.com/TravondeCompany'>
									<button>
										<img className='snsbutton' src={fb} alt='facebook' />
									</button>
								</a>
								<a href='https://twitter.com/travondei'>
									<button>
										<img className='snsbutton' src={twitter} alt='twitter' />
									</button>
								</a>
								{/* <a href='https://www.instagram.com/travonde/?hl=en'>
									<button>
										<img className='snsbutton' src={insta} alt='instagram' />
									</button>
								</a> */}
								<a href='https://www.youtube.com/channel/UCwIvHU5CVYZAse9aEqNG_Tw'>
									<button>
										<img className='snsbutton' src={youtube} alt='youtube' />
									</button>
								</a>
								{/* <a href = "https://www.pinterest.com">
                            <button>
                                <img className = 'snsbutton' src = {pin} alt =""/>
                            </button>
                        </a> */}
							</div>
						</Col>
					</Row>
					<br />
					<p className='copyright'>
						&copy; Travonde, Inc. All rights reserved.{' '}
						<a className='tandc' href='./termsandconditions'>
							Terms
						</a>{' '}
						- Privacy - Sitemap
					</p>
				</div>
			</div>
		);
	}
}

export default Footer;
