import { UPDATE_FILTER } from '../../redux/types';

const initialState = {
	city: '',
	price: '',
	date: '',
	eventType: '',
	online: false,
	videoFilter: false
};

export default function updateFilterQuery (state = initialState, action) {
	if (action.type === UPDATE_FILTER) {
		if (
			action.payload.city === '' &&
			action.payload.date === '' &&
			action.payload.price === '' &&
			action.payload.eventType === ''
		) {
			return {
				city: 'Any',
				date: 'any',
				price: 0,
				eventType: 'All',
				online: false,
				videoFilter: false
			};
		}
		if (action.payload.city) {
			return {
				...state,
				city: action.payload.city
			};
		}
		if (action.payload.date) {
			return {
				...state,
				date: action.payload.date
			};
		}
		if (action.payload.price) {
			return {
				...state,
				price: action.payload.price
			};
		}
		if (action.payload.eventType) {
			return {
				...state,
				eventType: action.payload.eventType
			};
		}
		if (action.payload.online === true || action.payload.online === false) {
			return {
				...state,
				online: action.payload.online
			};
		}
		if (action.payload.videoFilter === true || action.payload.videoFilter === false) {
			return {
				...state,
				videoFilter: action.payload.videoFilter
			};
		}
	}
	return state;
}
