import React, { Component, Fragment } from 'react';

import PostSignup from '../../PostSignup';
import InterestsSetup from './interestsSetup';
import InterestsDetailsSetup from './InterestsDetailsSetup';
import ActivitySetup from './ActivitySetup';
import AgeSetup from './AgeSetup';
import { Dialog, DialogContent } from '@material-ui/core';
import EndPage from './EndPage';

class SetupMain extends Component {
	constructor (props) {
		super(props);

		this.state = {
			page: true,
			page1: false,
			page2: false,
			page3: false,
			page4: false,
			page5: false,

			likeVolunteer: false,
			likeTaste: false,
			likeTour: false,
			likeLearn: false,
			likeCommunity: false,

			open: this.props.open
		};
	}
	callback = (close) => {
		this.setState({
			open: close,
			page: false,
			page1: true
		});
	};

	callbackInterests = (childData) => {
		this.setState({
			page1: false,
			page2: true,

			likeVolunteer: childData.likeVolunteer,
			likeTaste: childData.likeTaste,
			likeTour: childData.likeTour,
			likeLearn: childData.likeLearn,
			likeCommunity: childData.likeCommunity
		});
	};
	callbackDetails = () => {
		this.setState({
			page2: false,
			page3: true
		});
	};
	callbackActivity = () => {
		this.setState({
			page3: false,
			page4: true
		});
	};
	callbackAge = () => {
		this.setState({
			page4: false,
			page5: true
		});
	};

	handleOpen = () => {
		this.setState({
			open: true
		});
	};

	handleClose = () => {
		this.setState({
			open: false
		});
	};

	render () {
		return (
			<Fragment>
				{/*
                <MyButton
                    tip="Edit Details"
                    onClick={this.handleOpen}
                    //btnClassName={classes.button}
                    style={{ color: "#367786" }}
                >
                    <p>Click here to edit profile</p>
                    {/*<EditIcon color="primary" />  */}
				{/*</MyButton>*/}

				<Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth={false}>
					<DialogContent>
						{this.state.page ? <PostSignup parentCallback={this.callback} /> : null}
						{this.state.page1 ? <InterestsSetup parentCallback={this.callbackInterests} /> : null}
						{this.state.page2 ? (
							<InterestsDetailsSetup details={this.state} parentCallback={this.callbackDetails} />
						) : null}
						{this.state.page3 ? <ActivitySetup parentCallback={this.callbackActivity} /> : null}
						{this.state.page4 ? <AgeSetup parentCallback={this.callbackAge} /> : null}
						{this.state.page5 ? <EndPage parentCallback={this.handleClose} /> : null}
					</DialogContent>
				</Dialog>
			</Fragment>
		);
	}
}

export default SetupMain;
