import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import AppIcon from '../images/TRAVONDE_BLUES-BETA.png';
import { Link } from 'react-router-dom';

//MUI stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

//Redux stuff
import { connect } from 'react-redux';
import { signupUser } from './UserProfile/userActions';
import AntTypography from 'antd/lib/typography';

const styles = {
	typography: {
		useNextVariants: true
	},
	form: {
		textAlign: 'center'
	},
	image: {
		margin: '20px auto 20px auto'
	},
	pageTitle: {
		margin: '10px auto 10px auto'
	},
	textField: {
		margin: '10px auto 10px auto'
	},
	button: {
		marginTop: 20,
		position: 'relative'
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	progress: {
		position: 'absolute'
	}
};
/*
const styles = (theme) => ({
	...theme
});*/

class signup extends Component {
	constructor () {
		super();
		//TODO: this is where we need to change for bio information?
		this.state = {
			email: '',
			password: '',
			confirmPassword: '',
			handle: '',
			errorMessage: ''
		};
	}

	componentWillReceiveProps (nextProps) {
		if (nextProps.UI.errors) {
			this.setState({ errors: nextProps.UI.errors });
		}
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({
			loading: true
		});

		const { email, password, confirmPassword } = this.state;
		if (password !== confirmPassword) {
			this.setState({ errorMessage: 'Confirmation password must match password', loading: false });
			return;
		} else {
			this.setState({ errorMessage: '' });
		}
		const newUserData = {
			email,
			password,
			confirmPassword,
			handle: this.state.handle
		};

		this.props.signupUser(newUserData, this.props.history);
	};

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	render () {
		const { classes, UI: { loading, errors } } = this.props;
		const { errorMessage } = this.state;
		return (
			<Grid container className={classes.form} style= {{padding: '15px'}}>
				<Grid item sm />
				<Grid item sm>
					<img src={AppIcon} alt='TravondeLogo' className={classes.image} height='auto' width='100%' />
					<Typography variant='h2' className={classes.pageTitle}>
						Sign Up
					</Typography>
					<form noValidate onSubmit={this.handleSubmit} autoComplete='off'>
						<TextField
							id='email'
							name='email'
							type='email'
							label='Email'
							className={classes.textField}
							helperText={errors && errors.email}
							error={errors && errors.email ? true : false}
							value={this.state.email}
							onChange={this.handleChange}
							fullWidth
						/>
						<TextField
							id='password'
							name='password'
							type='password'
							label='Password'
							className={classes.textField}
							helperText={errors && errors.password}
							error={errors && errors.password ? true : false}
							value={this.state.password}
							onChange={this.handleChange}
							fullWidth
						/>
						<TextField
							id='confirmPassword'
							name='confirmPassword'
							type='password'
							label='Confirm Password'
							className={classes.textField}
							helperText={errorMessage}
							error={errorMessage !== '' ? true : false}
							value={this.state.confirmPassword}
							onChange={this.handleChange}
							fullWidth
						/>
						<TextField
							id='handle'
							name='handle'
							type='text'
							label='Username'
							className={classes.textField}
							helperText={errors && errors.handle}
							error={errors && errors.handle ? true : false}
							value={this.state.handle}
							onChange={this.handleChange}
							fullWidth
						/>
						{errors &&
						errors.general && (
							<Typography variant='body2' className={classes.customError}>
								{errors.general}
							</Typography>
						)}
						<Button
							type='submit'
							variant='contained'
							color='primary'
							className={classes.button}
							disabled={loading}
						>
							Sign Up
							{loading && <CircularProgress size={30} className={classes.progress} />}
						</Button>
						<br />
						<br />
						<AntTypography.Text>
							Already have an account? Log In{' '}
							<Link to='/login' component={AntTypography.Link}>
								here
							</Link>
						</AntTypography.Text>
						<br/>
						<AntTypography.Text>
							By signing up, I accept Travonde's{' '}
							<Link to='/termsandconditions' component={AntTypography.Link}>
								Terms and Conditions 
							</Link>
						</AntTypography.Text>
					</form>
				</Grid>
				<Grid item sm />
			</Grid>
		);
	}
}

signup.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired,
	signupUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI
});

export default connect(mapStateToProps, { signupUser })(withStyles(styles)(signup));
