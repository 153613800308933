import {
	SET_EVENTS,
	LOADING_DATA,
	DELETE_EVENT,
	SET_EVENT,
	SET_RELATED_EVENTS,
	SET_FEATURED_EVENTS,
	SET_CITIES,
	SET_CITIES_FAIL,
	SET_FEATURED_EVENTS_ORDER_SUCCESS,
	SET_FEATURED_EVENTS_ORDER_FAIL,
	SET_FEATURED_VIDEOS_SUCCESS,
	SET_FEATURED_VIDEOS_FAIL,
	SET_ANNOUNCEMENTS_SUCCESS,
	SET_ANNOUNCEMENTS_FAIL
} from '../../redux/types';

const initialState = {
	fullEvents: [],
	events: [],
	featuredEvents: [],
	featuredVideos: [],
	featuredEventsOrdering: {},
	recommendedEvents: [],
	visibleFeaturedEvents: [],
	visibleRecommendedEvents: [],
	cities: [],
	event: {},
	loading: false,
	announcements: {}
};

export default function (state = initialState, action) {
	//TODO
	//console.log('state :>> ', state);
	switch (action.type) {
		case LOADING_DATA:
			return {
				...state,
				loading: true
			};
		case SET_EVENTS:
			return {
				...state,
				fullEvents: action.payload,
				events: action.payload,
				event: {},
				loading: false
			};
		case SET_FEATURED_EVENTS:
			return {
				...state,
				featuredEvents: action.payload,
				event: {},
				loading: false
			};
		case SET_FEATURED_VIDEOS_SUCCESS:
			return {
				...state,
				featuredVideos: action.payload,
				loading: false
			};
		case SET_FEATURED_EVENTS_ORDER_SUCCESS:
			return {
				...state,
				featuredEventsOrdering: action.payload,
				loading: false
			};
		case SET_FEATURED_VIDEOS_FAIL:
			return {
				...state,
				featuredVideos: [],
				loading: false
			};
		case SET_FEATURED_EVENTS_ORDER_FAIL:
			return {
				...state,
				featuredEventsOdering: {}
			};
		case SET_EVENT:
			return {
				...state,
				event: action.payload
			};
		case SET_RELATED_EVENTS:
			return {
				...state,
				relatedEvents: action.payload
			};
		case SET_CITIES:
			return {
				...state,
				cities: action.payload
			};
		case SET_CITIES_FAIL:
			return {
				...state,
				cities: action.payload
			};
		case DELETE_EVENT:
			let index = state.events.findIndex((event) => event.eventID === action.payload);
			state.events.splice(index, 1);
			return {
				...state
			};
		case SET_ANNOUNCEMENTS_SUCCESS:
			return {
				...state,
				announcements: action.payload
			};
		case SET_ANNOUNCEMENTS_FAIL:
			return {
				...state,
				announcements: {}
			};
		default:
			return state;
	}
}
