import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
import './FilterBar.css';
import 'semantic-ui-css/semantic.min.css';

import Button from '@material-ui/core/Button';

import { updateFilterQuery } from './filterActions';
import { getCities } from '../../pages/EventPages/dataActions';
import { Form } from 'semantic-ui-react';
import searchButton from './searchIcon.png';

class FilterBar extends Component {
	componentDidMount () {
		const { getCities, cityOptions } = this.props;
		if (cityOptions.length === 0) {
			getCities();
		}
	}

	handleSearchButtonClick = async () => {
		await updateFilterQuery({ eventType: '' });
		this.props.history.push('/searchResults');
	};

	render () {
		const dateOptions = [
			{ key: 'm', text: 'Any', value: 'any' },
			{ key: 'f', text: 'Month', value: 'month' },
			{ key: 'w', text: 'Week', value: 'week' },
			{ key: 't', text: 'tomorrow', value: 'tomorrow' },
			{ key: '0', text: 'Sunday', value: '0' },
			{ key: '1', text: 'Monday', value: '1' },
			{ key: '2', text: 'Tuesday', value: '2' },
			{ key: '3', text: 'Wednesday', value: '3' },
			{ key: '4', text: 'Thursday', value: '4' },
			{ key: '5', text: 'Friday', value: '5' },
			{ key: '6', text: 'Saturday', value: '6' }
		];
		// const priceOptions = [
		// 	{ key: 'price0', text: 'Any', value: '0' },
		// 	{ key: 'price1', text: 'Free', value: '1' },
		// 	{ key: 'price2', text: '$0 - $29', value: '2' },
		// 	{ key: 'price3', text: '$30 - $99', value: '3' },
		// 	{ key: 'price4', text: '$100+', value: '4' }
		// ];

		const handleChangeDate = (e, { value }) => {
			this.props.updateFilterQuery({ date: value });
		};

		const handleChangeCity = (e, { value }) => {
			this.props.updateFilterQuery({ city: value });
		};

		const { cityOptions } = this.props;
		let options = [ { key: 'm', text: 'Any', value: '' } ];
		if (cityOptions.length !== 0) {
			for (let i = 0; i < cityOptions.length; i++) {
				const cityName = cityOptions[i];
				options.push({ key: `${cityName}${i}`, text: cityName, value: cityName });
			}
		}

		return (
			<div className='Filter-Bar-Style'>
				<Form>
					<Form.Select
						fluid
						label='I want to explore'
						options={dateOptions}
						placeholder='Any Date'
						onChange={handleChangeDate}
					/>
				</Form>
				<br />
				<Form>
					<Form.Select fluid label='in' options={options} placeholder='City' onChange={handleChangeCity} />
				</Form>
				<br />
				<div className='search-results-button'>
					<Button
						//variant="contained"
						// component={Link}
						// to={'/searchResults'}
						onClick={() => this.handleSearchButtonClick()}
					>
						<img src={searchButton} alt='search' />
					</Button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	city: state.filter.city,
	price: state.filter.price,
	date: state.filter.date,
	cityOptions: state.events.cities
});

const mapDispatchToProps = {
	updateFilterQuery,
	getCities
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
