import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import AppIcon from '../images/TRAVONDE_BLUES-BETA.png';
import { Link } from 'react-router-dom';
import AntTypography from 'antd/lib/typography';

//MUI stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

//Redux
import { connect } from 'react-redux';
import { loginUser, getUserDetails } from './UserProfile/userActions';
import firebase from '../components/Firebase/firebase';

const styles = {
	typography: {
		useNextVariants: true
	},
	form: {
		textAlign: 'center'
	},
	image: {
		margin: '20px auto 20px auto'
	},
	pageTitle: {
		margin: '10px auto 10px auto'
	},
	textField: {
		margin: '10px auto 10px auto'
	},
	button: {
		marginTop: 20,
		position: 'relative'
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	progress: {
		position: 'absolute'
	}
};

class login extends Component {
	constructor (props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			errors: {}
		};
	}

	// UNSAFE_componentWillReceiveProps(nextProps) {
	// 	if (nextProps.UI.errors) {
	// 		this.setState({ errors: nextProps.UI.errors });
	// 	}
	// }

	handleSubmit = (event) => {
		const userData = {
			email: this.state.email,
			password: this.state.password
		};
		this.props.loginUser(userData, this.props.history);

		event.preventDefault();
	};

	

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	render () {
		const { classes, UI: { loading, errors } } = this.props;
		return (
			<Grid container className={classes.form} style={{ padding: '15px' }}>
				<Grid item sm />
				<Grid item sm>
					<img src={AppIcon} alt='TravondeLogo' className={classes.image} height='auto' width='100%' />
					<Typography variant='h2' className={classes.pageTitle}>
						Login
					</Typography>
					<form noValidate onSubmit={this.handleSubmit}>
						<TextField
							id='email'
							name='email'
							type='email'
							label='Email'
							className={classes.textField}
							helperText={errors && errors.email}
							error={errors && errors.email ? true : false}
							value={this.state.email}
							onChange={this.handleChange}
							fullWidth
						/>
						<TextField
							id='password'
							name='password'
							type='password'
							label='Password'
							className={classes.textField}
							helperText={errors && errors.password}
							error={errors && errors.password ? true : false}
							value={this.state.password}
							onChange={this.handleChange}
							fullWidth
						/>
						{errors &&
						errors.general && (
							<Typography variant='body2' className={classes.customError}>
								{errors.general}
							</Typography>
						)}
						<Button
							type='submit'
							variant='contained'
							color='primary'
							className={classes.button}
							disabled={loading}
						>
							Login
							{loading && <CircularProgress size={30} className={classes.progress} />}
						</Button>
						<br />
						<br />
						<AntTypography.Text>
							Don't have an account? Sign Up{' '}
							<Link to='/signup' component={AntTypography.Link}>
								here
							</Link>
						</AntTypography.Text>
						<br />
						<AntTypography.Text>
							<Link to='/ResetPassword' component={AntTypography.Link}>
								Forgot password?
							</Link>
						</AntTypography.Text>
					</form>
				</Grid>
				<Grid item sm />
			</Grid>
		);
	}
}

login.propTypes = {
	classes: PropTypes.object.isRequired,
	loginUser: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
	user: state.user,
	UI: state.UI
});

const mapActionsToProps = {
	loginUser,
	getUserDetails
};

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(login));
