import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Card } from 'antd';

import { Row } from 'antd';

import ImageUpload from '../../../components/ImageUpload';

const ProfilePicture = ({ userID, currentUser, handleImageChange, handleEditPicture }) => {
	return (
		<div>
			<Card bodyStyle={{ alignContent: 'center' }}>
				<Row type='flex' justify='center'>
					{currentUser.imageUrl !== '' ? (
						<Avatar size={128} src={currentUser.imageUrl} shape={'circle'} />
					) : (
						<Avatar size={128} icon={<UserOutlined />} shape={'circle'} />
					)}
				</Row>
				<br />
				<input type='file' hidden='hidden' id='imageInput' onChange={handleImageChange} />
				<Row type='flex' justify='center'>
					<ImageUpload userID={userID} />
				</Row>
			</Card>
		</div>
	);
};

export default ProfilePicture;
