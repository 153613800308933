import React from 'react';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import List from 'antd/lib/list';
import Item from 'antd/lib/list/Item';
import EmailShareButton from 'react-share/lib/EmailShareButton';
import EmailIcon from 'react-share/lib/EmailIcon';
import FacebookShareIcon from 'react-share/lib/FacebookIcon';
import FacebookShareButton from 'react-share/lib/FacebookShareButton';

import firebase from '../Firebase/firebase';

export const ShareModal = ({handleCancel, visible, url, event }) => {
	return (
		<Modal
			title='Share This Event!'
			visible={visible}
			onCancel={handleCancel}
			footer={[
				<Button key='cancel' onClick={handleCancel}>
					Cancel
				</Button>		
			]}		
			
		>
			<List>
				<Item>
					<EmailShareButton
						subject={'[TRAVONDE] What do you think of this activity at Travonde?'}
						body={
							'Check this out! I think you will really enjoy this activity at Travonde. \nI visit Travonde all the time and thought you’d like it!'
						}
						separator={'\n\n'}
						url={`https://travonde.com/event/${url}`}
					>
						<EmailIcon size={34} round={true} />
						Email
					</EmailShareButton>
				</Item>
				<Item>
					<FacebookShareButton url={`https://travonde.com/event/${url}`}>
						<FacebookShareIcon size={34} round={true} />
						Facebook
					</FacebookShareButton>
				</Item>
			</List>
		</Modal>
	);
};
