import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';

//Redux
import { Provider } from 'react-redux';
//import store from './redux/store';
import configureStore from './redux/store';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
//import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import themeFile from './util/theme';

//Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';

//Pages
import home from './pages/EventPages/HomePage/home';
import login from './pages/login';
import signup from './pages/signup';
import profile from './pages/UserProfile/ProfilePageDetails/ProfilePage';

import FullEvent from './pages/EventPages/FullEventDetails/FullEventComponents/fullEventDetails';
import searchResults from './pages/searchResults';
//TODO delete category page
//import CategoryPage from './pages/EventPages/CategoryPage/CategoryPage';
import ResetPassword from './pages/ResetPassword';
import EditProfile from './pages/UserProfile/ProfilePageDetails/EditProfile';
import termsandconditions from './pages/termsandconditions';
import PostSignup from './pages/PostSignup';
import about from './pages/About/about';

const theme = createMuiTheme(themeFile);
const store = configureStore();

const App = () => (
	<MuiThemeProvider theme={theme}>
		<Provider store={store}>
			<Router>
				<Navbar />
				<div style={{ backgroundColor: 'white' }}>
					<Switch>
						<Route exact path='/' component={home} />
						<Route exact path='/Profile' component={profile} />
						<Route exact path='/searchResults/:eventType' component={searchResults} />
						<Route path='/searchResults' component={searchResults} />
						<Route exact path='/login' component={login} />
						<Route exact path='/signup' component={signup} />
						<Route exact path='/event/:id' component={FullEvent} />
						<Route exact path='/Profile/EditProfile' component={EditProfile} />
						<Route exact path='/PostSignup' component={PostSignup} />
						<Route exact path='/ResetPassword' component={ResetPassword} />
						<Route exact path='/termsandconditions' component={termsandconditions} />
						<Route exact path='/about' component={about} />
					</Switch>
				</div>
				<Footer />
			</Router>
		</Provider>
	</MuiThemeProvider>
);

export default App;
