import React, { Component } from 'react';

import './PostSignup.css';

import { Link } from 'react-router-dom';
//import SetupMain from './UserProfile/ProfileSetup/SetupMain';
class PostSignup extends Component {
	handleSubmitContinue = () => {
		this.props.parentCallback(true);
	};
	handleSubmitClose = () => {
		this.props.parentCallback(false);
	};

	render () {
		return (
			<div className='post'>
				<h1 className='headtext'>Thank You for Becoming a Member!</h1>
				<p className='bodytext'>
					You're almost done! Take this quiz about yourself to discover your next adventure.
				</p>

				<Link to='./'>
					<button className='continue' style={{width:'100%'}} onClick={this.handleSubmitClose}>
						Skip this quiz for now
					</button>
				</Link>

				<button className='continue' style={{width:'100%'}} onClick={this.handleSubmitContinue}>
					Take this quiz to get started
				</button>
			</div>
		);
	}
}

export default PostSignup;
