import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//import AppIcon from '../images/TRAVONDE_BLUES-BETA.png';
import './about.css';

class AboutUs extends Component {
	render () {
		return (
			<div className='aboutUs'>
				<br />
				<div className='bgimg-1'>
					<div className='caption'>
						<h3
							style={{
								position: 'relative',
								top: '50px',
								textAlign: 'center',
								fontSize: '80px',
								color: '#ffffff'
							}}
						>
							About Us
						</h3>
					</div>
				</div>

				<div
					style={{
						color: '#777',
						backgroundColor: '#ffffff',
						textAlign: 'center',
						padding: '20px 80px'
					}}
				>
					<h3
						style={{
							textAlign: 'center'
						}}
					>
						Our Mission
					</h3>
				</div>

				<div class='row'>
					<div class='column'>
						<div className='photo2'>
							<img src={require('./Images/image2.jpg')} alt='image2' style={{ width: '100%' }} />
						</div>
					</div>

					<div class='column'>
						<div
							style={{
								position: 'relative'
							}}
						>
							<h3
								style={{
									textAlign: 'center',
									paddingTop: '10%',
									paddingLeft: '100px',
									paddingRight: '100px',
									color: '#000000'
								}}
							>
								STRENGTHEN COMMUNITY
							</h3>
							<div
								style={{
									color: '#000000',
									backgroundColor: '#ffffff',
									textAlign: 'center',
									whiteSpace: 'initial'
								}}
							>
								<h2
									style={{
										position: 'relative',
										paddingTop: '10%',
										paddingLeft: '100px',
										paddingRight: '100px',
										textAlign: 'center',
										color: '#000000'
									}}
								>
									We will work with organizations to foster diverse and inclusive communities.{' '}
								</h2>
							</div>
						</div>
					</div>
				</div>
				<br />

				<div class='row2'>
					<div class='column'>
						<div
							style={{
								position: 'relative'
							}}
						>
							<h3
								style={{
									textAlign: 'center',
									paddingTop: '10%',
									paddingLeft: '100px',
									paddingRight: '100px',
									color: '#000000'
								}}
							>
								TRUST
							</h3>
							<div
								style={{
									color: '#000000',
									backgroundColor: '#ffffff',
									textAlign: 'center',
									whiteSpace: 'initial'
								}}
							>
								<h2
									style={{
										position: 'relative',
										paddingTop: '10%',
										paddingLeft: '100px',
										paddingRight: '100px',
										textAlign: 'center',
										color: '#000000'
									}}
								>
									We will provide unbiased and comprehensive information to benefit our customers.
								</h2>
							</div>
							<br />
						</div>
					</div>

					<div class='column'>
						<div className='photo3'>
							<img src={require('./Images/image3.jpg')} alt='image3' style={{ width: '100%' }} />
						</div>
					</div>
				</div>

				<div class='row'>
					<div class='column'>
						<div className='photo3'>
							<img src={require('./Images/image4.jpg')} alt='image4' style={{ width: '100%' }} />
						</div>
					</div>

					<div class='column'>
						<div
							style={{
								position: 'relative'
							}}
						>
							<h3
								style={{
									textAlign: 'center',
									paddingTop: '10%',
									paddingLeft: '100px',
									paddingRight: '100px',
									color: '#000000'
								}}
							>
								EXPLORE, ENGAGE AND GROW
							</h3>
							<div
								style={{
									color: '#000000',
									backgroundColor: '#ffffff',
									textAlign: 'center',
									whiteSpace: 'initial'
								}}
							>
								<h2
									style={{
										position: 'relative',
										textAlign: 'center',
										paddingTop: '10%',
										paddingLeft: '100px',
										paddingRight: '100px',
										color: '#000000'
									}}
								>
									Through exploration and engagement, our customers will continue to grow in their
									life's journey.
								</h2>
							</div>
						</div>
					</div>
				</div>

				<br />

				<div class='row2'>
					<div class='column'>
						<div
							style={{
								position: 'relative'
							}}
						>
							<h3
								style={{
									textAlign: 'center',
									paddingTop: '10%',
									paddingLeft: '100px',
									paddingRight: '100px',
									color: '#000000'
								}}
							>
								DATA FOR GOOD
							</h3>
							<div
								style={{
									color: '#000000',
									backgroundColor: '#ffffff',
									textAlign: 'center',
									whiteSpace: 'initial'
								}}
							>
								<h2
									style={{
										position: 'relative',
										paddingTop: '10%',
										paddingLeft: '100px',
										paddingRight: '100px',
										textAlign: 'center',
										color: '#000000'
									}}
								>
									All data gathered will be used to benefit you and humanity.
								</h2>
							</div>
						</div>
					</div>

					<div class='column'>
						<div className='photo3'>
							<img src={require('./Images/image5.jpg')} alt='image5' style={{ width: '100%' }} />
						</div>
					</div>
				</div>

				<div
					style={{
						clear: 'both'
					}}
				/>

				<h3
					style={{
						top: '100px',
						textAlign: 'center',
						fontSize: '30px',
						color: '#000000',
						fontWeight: 'bold'
					}}
				>
					Let's get together and build a more connected community.
				</h3>
				<Link to={'/signup'}>
					<button className='button'>Sign Up</button>
				</Link>

				<div className='banner' />
			</div>
		);
	}
}

export default AboutUs;
// JavaScript source code
