import React, { Component } from 'react';
import { connect } from 'react-redux';
import Flex from '../components/Flex';
import AppIcon from '../images/TRAVONDE_BLUES-BETA.png';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Typography from 'antd/lib/typography';
import Modal from 'antd/lib/modal';
import Result from 'antd/lib/result';
import { resetUserPassword } from './UserProfile/userActions';
import { Link } from 'react-router-dom';

const layout = {
	labelCol: {
		span: 8
	},
	wrapperCol: {
		span: 16
	}
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16
	}
};

export class ResetPassword extends Component {
	constructor (props) {
		super(props);
		this.state = { visible: false };
	}
	onFinish = async (values) => {
		console.log('Success:', values);
		const { resetUserPassword } = this.props;
		await resetUserPassword(values);
		const { error } = resetUserPassword;
		if (error !== null) {
			this.setState({ visible: true });
		}
	};
	onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	handleCancel = () => {
		this.setState({ visible: false });
	};

	render () {
		const { visible } = this.state;
		const { recoverPassword: { error } } = this.props;
		return (
			<Flex flexDirection={'column'} alignItems={'center'}>
				<br />
				<img src={AppIcon} alt='TravondeLogo' height='125' width='420' />
				<Typography level={4}>Type in your e-mail below to reset your password</Typography>
				<br />
				<Form {...layout} name='basic' onFinish={this.onFinish} onFinishFailed={this.onFinishFailed}>
					<Form.Item
						label='E-mail'
						name='email'
						rules={[
							{
								required: true,
								message: 'Please input your email!'
							}
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item {...tailLayout}>
						<Button type='primary' htmlType='submit'>
							Submit
						</Button>
					</Form.Item>
				</Form>
				<Modal visible={visible} title={null} onCancel={this.handleCancel} footer={null}>
					{error === false ? (
						<Result
							status='success'
							title='Successfully sent reset link!'
							subTitle='Please check your email and follow the steps to reset your password.'
							extra={[
								<Link to='/'>
									<Button type='primary' key='console'>
										Home Screen
									</Button>
								</Link>
							]}
						/>
					) : error ? (
						<Result
							status='warning'
							title='Something went wrong Oops!'
							subTitle={`${error}`}
							extra={[
								<Link to='/'>
									<Button key='homeScreen'>Home Screen</Button>
								</Link>,
								<Link to='/signup'>
									<Button type='primary' key='signUp'>
										Sign Up
									</Button>
								</Link>
							]}
						/>
					) : null}
				</Modal>
			</Flex>
		);
	}
}

const mapStateToProps = (state) => ({
	recoverPassword: state.user.recoverPassword
});

const mapDispatchToProps = {
	resetUserPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
