import React, { Component } from 'react';

class Video extends Component {
	constructor (props) {
		super(props);
		this.state = {
			windowWidth: window.innerWidth
		};
	}
	handleResize = (e) => {
		this.setState({ windowWidth: window.innerWidth });
	};
	componentDidMount = () => {
		window.addEventListener('resize', this.handleResize);
	};

	componentWillUnMount = () => {
		window.addEventListener('resize', this.handleResize);
	};
	render () {
		const { windowWidth } = this.state;
		return (
			<div>
				<iframe
					title={`${this.props.videolink}`}
					width={windowWidth > 480 ? '470px' : '310px'}
					height={'207px'}
					src={this.props.videolink}
					frameborder='0'
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
					allowfullscreen='true'
				/>
			</div>
		);
	}
}

export default Video;
