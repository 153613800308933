import React, { Component } from 'react';
import PropTypes from 'prop-types';

//Redux
import { connect } from 'react-redux';
import { editUserDetails } from '../userActions';
import './Setup.css';
//import Flex from "../../../components/Flex";
import { Row } from 'antd';
//import { Link } from "react-router-dom";
//import { Form, Input, Button, Radio } from "antd";
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import { FormGroup } from "@material-ui/core";
import { Form } from 'semantic-ui-react';

import BusIcon from './BUS_TOUR_blue.png';
import CommunityIcon from './COMMUNITY_VOICE_blue.png';
import LearnIcon from './LEARN_BOOK_blue.png';
import TasteIcon from './ICE-CREAM-blue.png';
import VolunteerIcon from './VOLUNTEER_HEART_blue.png';

class interestsDetailsSetup extends Component {
	state = {
		volunteerDetails: '',
		learnDetails: '',
		tourDetails: '',
		tasteDetails: '',
		communityDetails: '',

		likeVolunteer: false,
		likeTaste: false,
		likeTour: false,
		likeLearn: false,
		likeCommunity: false
	};

	handleChange = (e, { name, value }) => this.setState({ [name]: value });

	componentDidMount () {
		const { curr } = this.props;

		console.log(this.props);
		//TODO: Need to not run this if currentUser is undefined
		if (curr) {
			this.mapUserDetailsToState(curr);
		}
	}

	mapUserDetailsToState = (currentUser) => {
		this.setState({
			likeTaste: currentUser.likeTaste ? currentUser.likeTaste : false,
			likeTour: currentUser.likeTour ? currentUser.likeTour : false,
			likeLearn: currentUser.likeLearn ? currentUser.likeLearn : false,
			likeVolunteer: currentUser.likeVolunteer ? currentUser.likeVolunteer : false,
			likeCommunity: currentUser.likeCommunity ? currentUser.likeCommunity : false
		});
	};

	handleSubmit = () => {
		let userDetails = {
			...this.props.currentUser
		};
		userDetails = {
			volunteerDetails: this.state.volunteerDetails,
			learnDetails: this.state.learnDetails,
			tourDetails: this.state.tourDetails,
			tasteDetails: this.state.tasteDetails,
			communityDetails: this.state.communityDetails
		};
		this.props.parentCallback();
		this.props.editUserDetails(this.props.currentUser.uid, userDetails);
	};

	render () {
		const { volunteerDetails, learnDetails, tourDetails, tasteDetails, communityDetails } = this.state;

		//const {curr} = this.props.curr

		return (
			<div className='ProfileSetup'>
				<Row type='flex' justify='center'>
					<p className='edithead'> Tell us more so we can make better recommendations</p>
				</Row>

				<Form size={'big'} key={'big'}>
					{this.props.details.likeTour ? (
						<Row type='flex' justify='center'>
							<p>
								{' '}
								<img className='icons' src={BusIcon} alt='' /> Tour: {' '}
							</p>
							<div className='detailform'>
								<Form.Input
									placeholder='Ex: Day Trip, Museum Tours, Scuba Diving, Fishing, Wine Tasting, Kayaking'
									name='tourDetails'
									value={tourDetails}
									onChange={this.handleChange}
								/>
							</div>
						</Row>
					) : null}

					{this.props.details.likeLearn ? (
						<Row type='flex' justify='center'>
							<p>
								{' '}
								<img className='icons' src={LearnIcon} alt='' /> Learn:{' '}
							</p>

							<div className='detailform'>
								<Form.Input
									placeholder='Ex: Cooking, Arts and Crafts, History, Gardening'
									name='learnDetails'
									value={learnDetails}
									onChange={this.handleChange}
								/>
							</div>
						</Row>
					) : null}

					{this.props.details.likeTaste ? (
						<Row type='flex' justify='center'>
							<p>
								{' '}
								<img className='icons' src={TasteIcon} alt='' /> Taste:{' '}
							</p>
							<div className='detailform'>
								<Form.Input
									placeholder='Ex: Asian, Dessert, Vegan, Mexican, Italian'
									name='tasteDetails'
									value={tasteDetails}
									onChange={this.handleChange}
								/>
							</div>
						</Row>
					) : null}

					{this.props.details.likeCommunity ? (
						<Row type='flex' justify='center'>
							<p>
								{' '}
								<img className='icons' src={CommunityIcon} alt='' /> Community:{' '}
							</p>
							<div className='detailform'>
								<Form.Input
									placeholder='Ex: Dating, Friendship, Newly Retired, Activity Partner, Mentor/Mentee'
									name='communityDetails'
									value={communityDetails}
									onChange={this.handleChange}
								/>
							</div>
						</Row>
					) : null}

					{this.props.details.likeVolunteer ? (
						<Row type='flex' justify='center'>
							<p>
								{' '}
								<img className='icons' src={VolunteerIcon} alt='' /> Volunteer:{' '}
							</p>
							<div className='detailform'>
								<Form.Input
									placeholder='Ex: Fighting Homelessness, Mentorship, Animal Rights, Human Rights'
									name='volunteerDetails'
									value={volunteerDetails}
									onChange={this.handleChange}
								/>
							</div>
						</Row>
					) : null}
				</Form>

				<Row type='flex' justify='center'>
					<button className='continue' onClick={this.handleSubmit}>
						Continue
					</button>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	currentUser: state.user.credentials,
	curr: state.user.currentUser
});

interestsDetailsSetup.propTypes = {
	editUserDetails: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { editUserDetails })(interestsDetailsSetup);
