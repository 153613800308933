import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ResultsFilter from '../components/FilterBar/ResultsFilter';
import { getFilteredEvents, getAnnouncements } from './EventPages/dataActions';
import { updateFilterQuery } from '../components/FilterBar/filterActions';
import EventCard from '../components/Events/EventCard';
import Flex from '../components/Flex';
import Pagination from 'antd/lib/pagination';
import Drawer from 'antd/lib/drawer';
import Button from '@material-ui/core/Button';

import Volunteer from '../components/Events/volunteer-heart-blue.png';
import Tour from '../components/Events/bus-tour-blue.png';
import Taste from '../components/Events/summer.png';
import Learn from '../components/Events/learn-book-blue.png';
import Community from './UserProfile/ProfileSetup/COMMUNITY_VOICE_blue.png';
import Online from '../images/onlineIcon.png';

import './searchResults.css';

const urlify = (text) => {
	const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/g;

	if (urlRegex.test(text)) {
		return text.replace(urlRegex, (url) => {
			return '<a href="' + url + '" target="_blank" rel="noreferrer noopener">' + url + '</a>';
		});
	} else {
		return text;
	}
};
class searchResults extends Component {
	constructor (props) {
		super(props);
		this.state = {
			loading: false,
			filteredEvents: [],
			visibleEvents: [],
			displayedEventType: 'All',
			noEvents: false,
			windowWidth: window.innerWidth,
			open: false,
			stateEventType: ''
		};
	}

	componentDidUpdate (prevProps, prevState) {
		const { events, eventType: currentEventType } = this.props;
		const { events: prevEvents, eventType: prevEventType } = prevProps;
		const { filteredEvents: prevFilteredEvents } = prevState;
		const { filteredEvents: currFilteredEvents } = this.state;
		if (prevEvents === undefined && events !== undefined) {
			const newVisibleEvents = [];
			for (let i = 0; i < 20; i++) {
				if (events[i]) {
					newVisibleEvents.push(events[i]);
				}
			}

			this.setState({ filteredEvents: events, visibleEvents: newVisibleEvents }, () => {
				this.handleFilterSearch();
			});
		} else if (prevEvents !== undefined && events !== undefined && prevEvents.length !== events.length) {
			const newVisibleEvents = [];
			for (let i = 0; i < 20; i++) {
				if (events[i]) {
					newVisibleEvents.push(events[i]);
				}
			}

			this.setState({ filteredEvents: events, visibleEvents: newVisibleEvents }, () => {
				this.handleFilterSearch();
			});
		} else if (
			prevFilteredEvents &&
			currFilteredEvents &&
			prevFilteredEvents.length !== currFilteredEvents.length
		) {
			this.setState({ filteredEvents: currFilteredEvents });
		} else if (prevProps.match.params.eventType !== this.props.match.params.eventType) {
			this.setState(
				{
					stateEventType: this.props.match.params.eventType
				},
				() => this.handleFilterSearch()
			);
		}
	}

	componentDidMount () {
		window.addEventListener('resize', this.handleResize);
		const { eventType: eventTypeFromUrl } = this.props.match.params;
		this.setState(
			{
				stateEventType: eventTypeFromUrl ? eventTypeFromUrl : ''
			},
			() => this.handleLoadSearchParameters()
		);
	}
	componentWillUnmount () {
		window.addEventListener('resize', this.handleResize);
	}

	handleLoadSearchParameters = async () => {
		const { city, price, date, events, getFilteredEvents, updateFilterQuery, getAnnouncements } = this.props;
		const { eventType: eventTypeFromUrl } = this.props.match.params;
		window.addEventListener('resize', this.handleResize);
		await updateFilterQuery({ eventType: eventTypeFromUrl ? eventTypeFromUrl : '' });
		await getAnnouncements();
		const priceR = this.priceRange(price);
		const endDate = this.filterDate(date);
		if (events === undefined || events.length === 0) {
			getFilteredEvents(city, date, endDate, priceR[0], priceR[1]);
		} else {
			this.handleFilterSearch();
		}
	};

	handleResize = (e) => {
		this.setState({ windowWidth: window.innerWidth });
	};

	showDrawer = () => {
		this.setState({
			open: true
		});
	};

	onClose = () => {
		this.setState({
			open: false
		});
	};

	handleFilterSearch = () => {
		const {
			city: cityFilter,
			price: priceFilter,
			date,
			eventType: propEventType,
			events,
			online: onlineFilter,
			videoFilter
		} = this.props;
		const { stateEventType } = this.state;
		const eventType = stateEventType || stateEventType === '' ? stateEventType : propEventType;
		const newDate = new Date();
		let filteredEvents = [];
		const newVisibleEvents = [];
		let priceR = this.priceRange(priceFilter);
		let endDate = this.filterDate(date);
		let dayOfWeek = -1;
		if (!endDate) {
			dayOfWeek = Number(date);
		}
		const minPrice1 = priceR[0];
		const maxPrice1 = priceR[1];
		let eventCounter = 0;
		if (
			(cityFilter === '' || cityFilter === 'Any') &&
			(priceFilter === '' || priceFilter === 0) &&
			(date === '' || date === 'any') &&
			(eventType === '' || eventType === 'All') &&
			onlineFilter === false &&
			videoFilter === false
		) {
			for (const event of events) {
				const { endDate: eventDate, startDate, onGoing } = event;
				const convertedEventDate =
					typeof eventDate === 'object'
						? new Date(eventDate.toDate())
						: typeof eventDate === 'string' ? new Date(eventDate) : null;
				const filterStartDate = new Date(startDate);
				if (convertedEventDate < newDate) {
				} else {
					// using the day of week filter
					if (dayOfWeek < 0) {
						if (convertedEventDate < endDate || onGoing) {
							filteredEvents.push(event);
						}
					} else {
						if (filterStartDate.getDay() === dayOfWeek) {
							filteredEvents.push(event);
						}
					}
				}
			}
			for (let i = 0; i < 20; i++) {
				if (filteredEvents[i]) {
					newVisibleEvents.push(filteredEvents[i]);
				}
			}
		} else {
			for (const event of events) {
				const {
					pricePerPerson,
					city,
					endDate: eventDate,
					startDate,
					onGoing,
					eventTypeFilter,
					featured,
					online,
					recordingUrl
				} = event;
				const convertedEventDate =
					typeof eventDate === 'object'
						? new Date(eventDate.toDate())
						: typeof eventDate === 'string' ? new Date(eventDate) : null;
				const filterStartDate = new Date(startDate);
				if (
					(eventType === '' ||
						(eventType === 'Featured' && featured) ||
						eventType === 'All' ||
						eventType === eventTypeFilter) &&
					((onlineFilter === true && online === true) || onlineFilter === false) &&
					((videoFilter === true && recordingUrl) || videoFilter === false)
				) {
					let nonNullPricePerPerson = pricePerPerson;
					if (!nonNullPricePerPerson && nonNullPricePerPerson !== 0) {
						nonNullPricePerPerson = 0;
					}
					if (nonNullPricePerPerson <= maxPrice1 && nonNullPricePerPerson >= minPrice1) {
						if (city === cityFilter || cityFilter === '' || cityFilter === 'Any') {
							// checking if the event has passed
							if (convertedEventDate < newDate && !videoFilter) {
							} else {
								// using the day of week filter
								if (dayOfWeek < 0 || videoFilter) {
									if (convertedEventDate < endDate || onGoing || videoFilter) {
										filteredEvents.push(event);
										if (eventCounter < 20) {
											newVisibleEvents.push(event);
											eventCounter++;
										}
									}
								} else {
									if (filterStartDate.getDay() === dayOfWeek || videoFilter) {
										filteredEvents.push(event);
										if (eventCounter < 20) {
											newVisibleEvents.push(event);
											eventCounter++;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		const filteredEventsWithOrderNumber = filteredEvents.filter((event) => event.orderNumber);
		const filteredEventsWithoutOrderNumber = filteredEvents.filter((event) => !event.orderNumber);
		const visibleEventsWithOrderNumber = newVisibleEvents.filter((event) => event.orderNumber);
		const visibleEventsWithoutOrderNumber = newVisibleEvents.filter((event) => !event.orderNumber);
		let sortedFilteredEvents = [];
		let sortedVisibleEvents = [];
		if (filteredEventsWithOrderNumber.length !== 0) {
			const tempSortedFilteredEvents = filteredEventsWithOrderNumber.sort(
				(a, b) => a.orderNumber - b.orderNumber
			);
			sortedFilteredEvents = [ ...tempSortedFilteredEvents ];
		}
		sortedFilteredEvents = [ ...sortedFilteredEvents, ...filteredEventsWithoutOrderNumber ];
		if (visibleEventsWithOrderNumber.length !== 0) {
			const tempSortedVisibleEvents = visibleEventsWithOrderNumber.sort((a, b) => a.orderNumber - b.orderNumber);
			sortedVisibleEvents = [ ...tempSortedVisibleEvents ];
		}
		sortedVisibleEvents = [ ...sortedVisibleEvents, ...visibleEventsWithoutOrderNumber ];
		if (onlineFilter && !videoFilter) {
			this.setState({
				filteredEvents: sortedFilteredEvents,
				displayedEventType: eventType === '' ? 'All Online' : `Online ${eventType}`,
				visibleEvents: sortedVisibleEvents,
				noEvents: filteredEvents.length === 0
			});
		} else if (!onlineFilter && videoFilter) {
			this.setState({
				filteredEvents: sortedFilteredEvents,
				displayedEventType: eventType === '' ? 'All Videos' : `${eventType} Videos`,
				visibleEvents: sortedVisibleEvents,
				noEvents: filteredEvents.length === 0
			});
		} else if (onlineFilter && videoFilter) {
			this.setState({
				filteredEvents: sortedFilteredEvents,
				displayedEventType: eventType === '' ? 'All Online Videos' : `Online ${eventType} Videos`,
				visibleEvents: sortedVisibleEvents,
				noEvents: filteredEvents.length === 0
			});
		} else {
			this.setState({
				filteredEvents: sortedFilteredEvents,
				displayedEventType: eventType === '' ? 'All' : eventType,
				visibleEvents: sortedVisibleEvents,
				noEvents: filteredEvents.length === 0
			});
		}
	};

	handlePageChange = (page, pageSize) => {
		const { filteredEvents } = this.state;
		const newVisibleEvents = [];
		for (let i = (page - 1) * pageSize; i < page * pageSize; i++) {
			if (filteredEvents[i]) {
				newVisibleEvents.push(filteredEvents[i]);
			}
		}
		this.setState({ visibleEvents: newVisibleEvents });
		window.scrollTo(0, 0);
	};

	render () {
		const { filteredEvents, displayedEventType, visibleEvents, noEvents, windowWidth, open } = this.state;
		const { announcements } = this.props;
		let displayAnnouncement = null;
		if (Object.keys(announcements).length !== 0) {
			const currentEventType = displayedEventType.toLowerCase();
			if (currentEventType.includes('community')) {
				displayAnnouncement = announcements['community'];
			} else if (currentEventType.includes('volunteer')) {
				displayAnnouncement = announcements['volunteer'];
			} else if (currentEventType.includes('tour')) {
				displayAnnouncement = announcements['tour'];
			} else if (currentEventType.includes('learn')) {
				displayAnnouncement = announcements['learn'];
			} else if (currentEventType.includes('taste')) {
				displayAnnouncement = announcements['taste'];
			} else {
				displayAnnouncement = null;
			}
			if (displayAnnouncement && displayAnnouncement !== '') {
				while (displayAnnouncement.includes('*')) {
					displayAnnouncement = displayAnnouncement.replace('*', '<br />');
				}
				displayAnnouncement = urlify(displayAnnouncement);
			}
		}
		let image;
		if (displayedEventType.includes('Tour')) {
			image = <img src={Tour} alt='tour' style={{ height: '25px' }} />;
		}
		if (displayedEventType.includes('Learn')) {
			image = <img src={Learn} alt='learn' style={{ height: '25px' }} />;
		}
		if (displayedEventType.includes('Taste')) {
			image = <img src={Taste} alt='taste' style={{ height: '25px' }} />;
		}
		if (displayedEventType.includes('Community')) {
			image = <img src={Community} alt='community' style={{ height: '25px' }} />;
		}
		if (displayedEventType.includes('Volunteer')) {
			image = <img src={Volunteer} alt='volunteer' style={{ height: '25px' }} />;
		}
		if (displayedEventType === 'All Online') {
			image = <img src={Online} alt='online' style={{ height: '25px' }} />;
		}

		return (
			<div className='wrapper'>
				{windowWidth > 460 ? (
					<div className='sidebar'>
						<ResultsFilter handleFilterSearch={this.handleFilterSearch} history={this.props.history} />
					</div>
				) : (
					<Fragment>
						<Drawer
							title='Event Filters'
							placement={'left'}
							closable={false}
							onClose={this.onClose}
							visible={open}
							key={'left'}
						>
							<ResultsFilter
								handleFilterSearch={this.handleFilterSearch}
								onClose={this.onClose}
								history={this.props.history}
							/>
						</Drawer>
					</Fragment>
				)}
				<div className='main'>
					<div className='container'>
						<Flex flexDirection={'row'} flexWrap={'wrap'}>
							{windowWidth <= 460 && (
								<Button
									color='primary'
									variant='contained'
									onClick={this.showDrawer}
									style={{ marginTop: '-30px', marginBottom: '20px' }}
								>
									Filters
								</Button>
							)}
							{filteredEvents ? (
								<Fragment>
									<Flex flexDirection='column'>
										<Flex flexDirection={'row'} justifyContent={'space-evenly'}>
											<Flex style={{ padding: `5px` }}>{image}</Flex>
											<Flex>
												{displayedEventType.includes('Videos') ? (
													<h1>{`${displayedEventType}`}</h1>
												) : displayedEventType.toLowerCase().includes('community') ? (
													<div>
														<h1>{`Community Day Events`}</h1>
														{displayAnnouncement && (
															<div
																className={'announcements'}
																key={'announcementForFilter'}
																dangerouslySetInnerHTML={{
																	__html: displayAnnouncement
																}}
															/>
														)}
													</div>
												) : (
													<h1>{`${displayedEventType} Events`}</h1>
												)}
											</Flex>
										</Flex>

										{filteredEvents.length !== 0 ? (
											<Fragment>
												<Flex flexDirection={'row'} flexWrap={'wrap'}>
													{visibleEvents.map((event) => (
														<Flex
															key={event.uid}
															justifyContent={'space-evenly'}
															style={{ padding: 5, margin: 16 }}
														>
															<EventCard key={event.uid} event={event} />
														</Flex>
													))}
												</Flex>
												<Pagination
													total={filteredEvents.length}
													showTotal={(total, range) =>
														`${range[0]}-${range[1]} of ${total} items`}
													defaultPageSize={20}
													defaultCurrent={1}
													onChange={this.handlePageChange}
													onShowSizeChange={this.handlePageChange}
												/>
											</Fragment>
										) : !noEvents ? (
											<h4>Loading Events...</h4>
										) : (
											<h4>
												No events matching your current search. Please choose different filters.
											</h4>
										)}
									</Flex>
								</Fragment>
							) : (
								<p>Oops! Something went wrong please refresh the page.</p>
							)}
						</Flex>
					</div>
				</div>
			</div>
		);
	}

	// functions to get the filter data

	// getting the filter info from the URL
	getJsonFromUrl (url) {
		if (!url) url = window.location.search;
		var query = url.substr(1);
		var result = {};
		query.split('&').forEach(function (part) {
			var item = part.split('=');
			result[item[0]] = decodeURIComponent(item[1]);
		});
		return result;
	}

	// gives the the date of the filter range
	filterDate (filter) {
		var rDate = new Date();
		var date = new Date().getDate(); //Current Date
		var month = new Date().getMonth(); //Current Month

		switch (filter) {
			case 'month':
				month++;
				rDate.setMonth(month);
				break;
			case 'tomorrow':
				date++;
				rDate.setDate(date);
				break;
			case '':
				rDate.setYear(2100);
				break;
			case 'week':
				date += 7;
				rDate.setDate(date);
				break;
			case 'any':
				rDate.setYear(2100);
				break;
			// when filter is a day of the week
			default:
				return false;
		}

		return rDate;
	}

	/* getting the price range from the url value passed in
   * pass outs a array of ints with [minprice, maxprice]
   */
	priceRange (value) {
		var min;
		var max;
		switch (value) {
			case '0':
				min = 0;
				max = 100000000000;
				break;
			case '1':
				min = 0;
				max = 0;
				break;
			case '2':
				min = 0;
				max = 30;
				break;
			case '3':
				min = 30;
				max = 100;
				break;
			case '4':
				min = 100;
				max = 100000000000;
				break;
			default:
				min = 0;
				max = 100000000000;
		}
		return [ min, max ];
	}
}

searchResults.propTypes = {
	data: PropTypes.object.isRequired,
	getFilteredEvents: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	data: state.events,
	events: state.events.events,
	price: state.filter.price,
	city: state.filter.city,
	date: state.filter.date,
	eventType: state.filter.eventType,
	online: state.filter.online,
	videoFilter: state.filter.videoFilter,
	announcements: state.events.announcements
});

const mapDispatchToProps = {
	getFilteredEvents,
	updateFilterQuery,
	getAnnouncements
};

export default connect(mapStateToProps, mapDispatchToProps)(searchResults);
