import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getFeaturedEvents, getFeaturedEventsOrdering, getFeaturedVideos } from '../dataActions';
import FilterBar from '../../../components/FilterBar/FilterBar';
import CarouselItems from './CarouselItems';

import EventCard from '../../../components/Events/EventCard';
import './home.css';
import Flex from '../../../components/Flex';
import desktopImage from './BackgroundBanner.png';
import mobileImage from './BackgroundBannerMobile.png';
import SetupMain from '../../UserProfile/ProfileSetup/SetupMain';
import EventBanner from './BannerPlaceholder.png';

import firebase from '../../../components/Firebase/firebase';

class home extends Component {
	//TODO: Add componentWillUnmount function?

	componentDidMount () {
		firebase.analytics().logEvent('visit_homepage');
		this.getDataOnLoad();
	}


	getDataOnLoad = async () => {
		const { getFeaturedEvents, getFeaturedEventsOrdering, getFeaturedVideos } = this.props;
		await getFeaturedEventsOrdering();
		await getFeaturedEvents();
		await getFeaturedVideos();
	};

	render () {
		//   *** New Code *** 2 CSS Classes and media query can select based on class
		const { featuredEvents, featuredEventsOrdering, featuredVideos, loading } = this.props;
		const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage; // not good for performance

		const orderedFeaturedEvents = [];
		const addedEventIds = [];
		if (featuredEventsOrdering.priorities) {
			for (const eventId of featuredEventsOrdering.priorities) {
				const tempEvent = featuredEvents.find((event) => event.uid === eventId);
				if (tempEvent) {
					orderedFeaturedEvents.push(tempEvent);
					addedEventIds.push(eventId);
				}
			}
		}

		if (featuredEvents.length !== orderedFeaturedEvents) {
			for (let i = 0; i < featuredEvents.length; i++) {
				if (!addedEventIds.includes(featuredEvents[i].uid)) {
					orderedFeaturedEvents.push({ ...featuredEvents[i] });
				}
			}
		}

		return (
			<div>
				<br />
				<SetupMain open={this.props.location.search === '?postsignup=true'} />
				<div
					className='Banner'
					style={{
						backgroundImage: `url(${imageUrl})`,
						height: '350px',
						backgroundSize: 'cover'
					}}
				>
					<Flex
						flexDirection={'row'}
						flexWrap={'wrap'}
						justifyContent={'space-between'}
						padding={'40px'}
						alignItems={'center'}
					>
						<Flex flexDirection={'column'} flexWrap={'wrap'} padding={'40px'} alignItems={'center'}>
							<div className='Banner-quotes'>
								<p className='Banner-q'>The biggest adventure you can take is to</p>
								<h1 className='Banner-q'>
									LIVE THE LIFE OF
									<br /> YOUR DREAMS
								</h1>
							</div>
						</Flex>

						<Flex flexDirection={'column'} flexWrap={'wrap'} padding={'40px'}>
							<div className='FilterBar-syle-div' style={{ padding: '40px' }}>
								<FilterBar history={this.props.history} />
							</div>
						</Flex>
					</Flex>
				</div>

				{/*
				Special Featured Event Banner
				
				*/}

				<div style={{ padding: '30px', paddingRight: '40px' }}>
					<h1>What do you want to do today?</h1>
					<CarouselItems />
				</div>

				<div style={{ padding: '40px', paddingRight: '40px' }}>
					<h1>Plan your next chapter with Travonde</h1>
					<h1>Featured Events</h1>

					<Flex flexDirection={'row'} flexWrap={'wrap'}>
						{!loading ? (
							featuredEvents &&
							featuredEvents.length !== 0 &&
							Object.keys(featuredEventsOrdering).length !== 0 &&
							orderedFeaturedEvents.map((event) => {
								return (
									<Flex
										key={event.uid}
										justifyContent={'flex-start'}
										style={{ padding: 0, margin: 20 }}
									>
										<EventCard key={event.uid} event={event} />
									</Flex>
								);
							})
						) : (
							<p>Loading...</p>
						)}
					</Flex>
					<br />
					{featuredVideos.length !== 0 && (
						<Fragment>
							<h1>Featured Videos</h1>
							<Flex flexDirection={'row'} flexWrap={'wrap'}>
								{!loading ? (
									featuredVideos &&
									featuredVideos.length !== 0 &&
									featuredVideos.map((event) => {
										return (
											<Flex
												key={event.uid}
												justifyContent={'flex-start'}
												style={{ padding: 0, margin: 20 }}
											>
												<EventCard key={event.uid} event={event} />
											</Flex>
										);
									})
								) : (
									<p>Loading...</p>
								)}
							</Flex>
						</Fragment>
					)}
				</div>
			</div>
		);
	}
}

home.propTypes = {
	data: PropTypes.object.isRequired,
	getFeaturedEvents: PropTypes.func.isRequired,
	getFeaturedEventsOrdering: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	data: state.events,
	featuredEventsOrdering: state.events.featuredEventsOrdering,
	featuredEvents: state.events.featuredEvents,
	featuredVideos: state.events.featuredVideos
});

const mapDispatchToProps = {
	getFeaturedEvents,
	getFeaturedEventsOrdering,
	getFeaturedVideos
};

export default connect(mapStateToProps, mapDispatchToProps)(home);
