import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './EventCard.css';

// import { Card } from "antd";
import Card from 'react-bootstrap/Card';
// import TypeIcon from './EventComponents/TypeIcon';
// import { StarFilled, ExportOutlined } from '@ant-design/icons';
import { Button } from 'antd';
// import { ShareAltOutlined } from '@ant-design/icons';
// import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import Volunteer from './volunteer-heart-blue.png';
import Community from '../../pages/UserProfile/ProfileSetup/COMMUNITY_VOICE_blue.png';
import Taste from './summer.png';
import Learn from './learn-book-blue.png';
import Tour from './bus-tour-blue.png';
//import Star from './star.png';
import Share from './share-1.png';
import Location from './maps-and-flags-1.png';
import { ShareModal } from '../ShareModal/ShareModal';
//import AntTypography from 'antd/lib/typography';
//const { Meta } = Card;

import { triggerFirebaseAnalytics } from '../Firebase/utils';

const Link = require('react-router-dom').Link;

function setDescription(event) {
	if (event.eventTypeFilter === 'Volunteer') {
		return 'Volunteering';
	} else {
		if (event.pricePerPerson === 0) {
			return 'Admission is Free';
		} else {
			if (event.pricePerPerson) {
				return '$' + event.pricePerPerson + ' per person';
			} else if (event.price) {
				return '$' + event.price + ' per person';
			} else {
				return '';
			}
		}
	}
}

class EventCard extends Component {
	constructor(props) {
		super(props);
		this.state = { visible: false };
	}

	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleOk = (e) => {
		console.log(e);
		this.setState({
			visible: false
		});
	};

	handleCancel = (e) => {
		this.setState({
			visible: false
		});
	};

	handleEventCardClick = () => {
		const { event } = this.props;
		const { pricePerPerson, city, eventTypeFilter, eventName } = event;
		triggerFirebaseAnalytics('event_card_clicks', {
			event_name: eventName,
			is_free: pricePerPerson
				? pricePerPerson === 0
					? true
					: false
				: true,
			event_type: eventTypeFilter,
			event_city: city
		});
	};

	render() {
		const { visible } = this.state;
		const { event } = this.props;
		// const eventid = this.props.match.params.id;

		if (event === null) {
			return null;
		}
		const { eventName, uid, eventTypeFilter, city, imageUrl } = event;
		//rating, imageUrl, pricePerPerson } } = this.props;

		let button;
		if (eventTypeFilter === 'Tour') {
			button = <img src={Tour} alt='tour' style={{ height: '25px' }} />;
		}
		if (eventTypeFilter === 'Learn') {
			button = <img src={Learn} alt='learn' style={{ height: '25px' }} />;
		}
		if (eventTypeFilter === 'Taste') {
			button = <img src={Taste} alt='taste' style={{ height: '25px' }} />;
		}
		if (eventTypeFilter === 'Volunteer') {
			button = (
				<img
					src={Volunteer}
					alt='volunteer'
					style={{ height: '25px' }}
				/>
			);
		}
		if (eventTypeFilter === 'Community') {
			button = (
				<img
					src={Community}
					alt='community'
					style={{ height: '25px' }}
				/>
			);
		}
		return (
			<div className='Card'>
				<Link
					to={'/event/'.concat(uid)}
					onClick={() => this.handleEventCardClick()}
				>
					<Card
						style={{
							width: '18rem',
							height: '580px',
							borderRadius: '30px',
							border: '0px'
						}}
					>
						<Card.Img
							variant='top'
							src={imageUrl}
							style={{
								borderRadius: '30px',
								height: '310px'
							}}
						/>
						<Card.Body>
							<div>
								<img
									src={Location}
									alt='location'
									style={{
										height: '15px',
										objectFit: 'contain'
									}}
								/>{' '}
								<span style={{ lineHeight: '15px' }}>
									{city}
								</span>
								<span
									className='topHeading'
									style={{ float: 'right' }}
								>
									{button}
								</span>
							</div>
							<h1>
								<span className='topHeading'>{eventName} </span>
							</h1>
							<div className='priceDescription'>
								<p>{setDescription(this.props.event)} </p>
							</div>
							{/* <p>{this.props.event.psricePerPerson}</p> */}
							<div
								className='bottomPortion'
								style={{
									position: 'absolute',
									width: '90%',
									bottom: '20px'
								}}
							>
								{/* <img
									src={Star}
									alt='rating'
									style={{ width: '20px', height: '20px', paddingBottom: '1px' }}
								/>{' '}
								<AntTypography.Text style={{ color: 'black' }}>{rating}</AntTypography.Text> */}
								<span style={{ float: 'right' }}>
									<Button //Sharing
										type='ghost'
										onClick={(e) => {
											this.showModal();
											e.preventDefault();
										}}
										style={{ border: '0' }}
									>
										<img
											src={Share}
											alt='share'
											className='share-1'
										/>
									</Button>
									<ShareModal
										visible={visible}
										url={uid}
										handleOk={(e) => {
											this.handleOk();
											e.preventDefault();
										}}
										handleCancel={(e) => {
											this.handleCancel();
											e.preventDefault();
										}}
									/>
								</span>
							</div>
						</Card.Body>
					</Card>
				</Link>
			</div>
		);
	}
}

export default EventCard;
