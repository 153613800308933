//User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHETICATED';
export const SET_USER = 'SET_USER';
export const SET_USER_DOC = 'SET_USER_DOC';
export const SET_USER_INT = 'SET_USER_INT';
export const LOADING_USER = 'LOADING_USER';
export const LOADING_USER_DOC = 'LOADING_USER_DOC';
export const EDITING_USER_DOC = 'EDITING_USER_DOC';

//UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';

//Data reducer types
export const SET_EVENTS = 'SET_EVENTS';
export const SET_INTERESTED_EVENTS = 'SET_INTERESTED_EVENTS';
export const SET_INTERESTED_EVENTS_FAIL = 'SET_INTERESTED_EVENTS_FAIL';
export const SET_RELATED_EVENTS = 'SET_RELATED_EVENTS';
export const SET_FEATURED_EVENTS = 'SET_FEATURED_EVENTS';
export const SET_FEATURED_EVENTS_ORDER = 'SET_FEATURED_EVENTS_ORDER';
export const SET_FEATURED_EVENTS_ORDER_SUCCESS = 'SET_FEATURED_EVENTS_ORDER_SUCCESS';
export const SET_FEATURED_EVENTS_ORDER_FAIL = 'SET_FEATURED_EVENTS_ORDER_FAIL';
export const SET_FEATURED_VIDEOS_SUCCESS = 'SET_FEATURED_VIDEOS_SUCCESS';
export const SET_FEATURED_VIDEOS_FAIL = 'SET_FEATURED_VIDEOS_FAIL';
export const SET_EVENT = 'SET_EVENT';
export const SET_EVENT_ID = 'SET_EVENT_ID';
export const LIKE_EVENT = 'LIKE_EVENT';
export const UNLIKE_EVENT = 'SETUNLIKE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const SET_ANNOUNCEMENTS_SUCCESS = 'SET_ANNOUNCEMENTS_SUCCESS';
export const SET_ANNOUNCEMENTS_FAIL = 'SET_ANNOUNCEMENTS_FAIL';

//Filter Reducer types
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const SET_CITIES = 'SET_CITIES';
export const SET_CITIES_FAIL = 'SET_CITIES_FAIL';

//Password Reducer types
export const RESET_START = 'RESET_START';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';
