import { UPDATE_FILTER } from '../../redux/types';
import firebase from '../Firebase/firebase';

export const updateFilterQuery = (payload) => (dispatch) => {
	return dispatch({
		type: UPDATE_FILTER,
		payload
	});
};

export const loadCityOptions = (options, resolve, reject) => {
	firebase
		.firestore()
		.collection('Cities')
		.get()
		.then((doc) => {
			if (doc.size) {
				doc.forEach((doc) => {
					let currCity = doc.data().city;
					options.push(currCity);
				});
				resolve(options);
			}
		})
		.catch((error) => {
			console.log(error);
			reject('Could not get city options');
		});
};
