import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import userReducer from '../pages/UserProfile/userReducer';
import dataReducer from '../pages/EventPages/dataReducer';
import uiReducer from './reducers/uiReducer';
import filterReducer from '../components/FilterBar/filterReducer';
import eventPageReducer from '../pages/EventPages/FullEventDetails/eventPageReducer';
import { verifyAuth } from '../pages/UserProfile/userActions';
//import { getEvents } from '../pages/EventPages/dataActions';

export default function configureStore (persistedState) {
	const middleware = [ thunk ];

	const reducers = combineReducers({
		events: dataReducer,
		eventPage: eventPageReducer,
		filter: filterReducer,
		user: userReducer,
		UI: uiReducer
	});

	const composeEnhancers =
		typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
			: compose;
	const enhancer = composeEnhancers(applyMiddleware(...middleware));
	const store = createStore(reducers, persistedState, enhancer);
	store.dispatch(verifyAuth());
	//store.dispatch(getEvents());
	return store;
}
