import React from 'react';
import { Card } from 'antd';

import BusIcon from '../ProfileSetup/BUS_TOUR_blue.png';
import CommunityIcon from '../ProfileSetup/COMMUNITY_VOICE_blue.png';
import LearnIcon from '../ProfileSetup/LEARN_BOOK_blue.png';
import TasteIcon from '../ProfileSetup/ICE-CREAM-blue.png';
import VolunteerIcon from '../ProfileSetup/VOLUNTEER_HEART_blue.png';

import Flex from '../../../components/Flex';

import InterestEdit from './EditDetails/InterestEdit';

const Interests = ({ currentUser, userID }) => {
	return (
		<div>
			<Card>
				<h1 className='InterestHead'>
					I am Interested In · <InterestEdit currentUser={currentUser} userID={userID} />
				</h1>

				<Flex flexDirection={'row'} flexWrap={'wrap'}>
					{currentUser.likeTour ? (
						<Flex
							flexDirection={'column'}
							alignItems={'center'}
							style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}
						>
							<img className='icons' src={BusIcon} alt='tour' />
							<p className='likes'>Travel</p>
						</Flex>
					) : null}
					{currentUser.likeVolunteer ? (
						<Flex
							flexDirection={'column'}
							alignItems={'center'}
							style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}
						>
							<img className='icons' src={VolunteerIcon} alt='volunteer' />
							<p className='likes'>Volunteer</p>
						</Flex>
					) : null}
					{currentUser.likeLearn ? (
						<Flex
							flexDirection={'column'}
							alignItems={'center'}
							style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}
						>
							<img className='icons' src={LearnIcon} alt='learn' />
							<p className='likes'>Learn</p>
						</Flex>
					) : null}
					{currentUser.likeTaste ? (
						<Flex
							flexDirection={'column'}
							alignItems={'center'}
							style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}
						>
							<img className='icons' src={TasteIcon} alt='taste' />
							<p className='likes'>Taste</p>
						</Flex>
					) : null}
					{currentUser.likeCommunity ? (
						<Flex
							flexDirection={'column'}
							alignItems={'center'}
							style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}
						>
							<img className='icons' src={CommunityIcon} alt='community' />
							<p className='likes'>Community</p>
						</Flex>
					) : null}
				</Flex>
			</Card>
		</div>
	);
};

export default Interests;
