import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import EventCard from '../../../components/Events/EventCard';
import { getInterestedEvents } from '../userActions';
const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 2000 },
		items: 5
	},
	largeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 2000, min: 1200 },
		items: 3
	},
	mobile: {
		breakpoint: { max: 900, min: 680 },
		items: 2
	},
	tinyMobile: {
		breakpoint: { max: 680, min: 0 },
		items: 1
	}
};
class InterestedEvents extends Component {
	constructor (props) {
		super(props);

		this.state = {
			loading: false
		};
	}

	componentDidMount () {
		const { userInterested, getInterestedEvents } = this.props;
		if (userInterested) {
			const { interests } = userInterested;
			if (interests) {
				getInterestedEvents(interests);
			}
		}
	}

	componentDidUpdate (prevProps, prevState) {
		if (prevProps.userInterested !== this.props.userInterested) {
			const { userInterested, getInterestedEvents } = this.props;
			if (userInterested) {
				const { interests } = userInterested;
				getInterestedEvents(interests);
				// let intEvent = interests.map((event) => {
				// 	return this.getEvent(event);
				// });

				// let temp = Promise.all(intEvent).then((vals) => this.setState({ events: vals }));
			}
		}
	}

	// getEvent = async (eventID) => {
	// 	var event = await firebase.firestore().collection('Events').doc(`${eventID}`);
	// 	let ret = null;
	// 	await event.get().then((docSnapshot) => {
	// 		if (docSnapshot.exists) {
	// 			console.log('here');

	// 			ret = docSnapshot.data();
	// 			ret.uid = docSnapshot.id;
	// 		} else {
	// 			console.log('there');
	// 			ret = null;
	// 		}
	// 	});
	// 	console.log(ret);

	// 	return ret;
	// };

	render () {
		const { interestedEvents } = this.props;

		return (
			interestedEvents.length !== 0 && (
				<Fragment>
					<h1>Interested</h1>
					<Carousel responsive={responsive}>
						{interestedEvents &&
							interestedEvents.map((event, index) => (
								<div key={`${event}${index}`} style={{ width: '75%' }}>
									<EventCard key={event.eventName} event={event} />
								</div>
							))}
					</Carousel>
				</Fragment>
			)
		);
	}
}

const mapStateToProps = (state) => ({
	data: state.events,
	interestedEvents: state.user.interestedEvents,
	uid: state.user.credentials.uid,
	userInterested: state.user.userInterested
});

const mapActionsToProps = { getInterestedEvents };
export default connect(mapStateToProps, mapActionsToProps)(InterestedEvents);
