import React, { Component } from "react";


import { Link } from "react-router-dom";

class EndPage extends Component {

    
  handleSubmitContinue = () => {
    
    this.props.parentCallback(true);


  };
  handleSubmitClose = () => {
    
    this.props.parentCallback(false);


  };

    render(){
       
        return(
            <div class = 'post'>
                <p className = 'headtext'>Thank you. Your results will help us give you better recommendations.</p>

                

                <Link to = "./">
                <button className="continue"  onClick={this.handleSubmitClose}>
                 I want to Explore
          </button>
          </Link>

          <Link to = "./profile">
                <button className="continue"  onClick={this.handleSubmitClose}>
                 Go to Profile
          </button>
          </Link>


            </div>
        )
            
        

    }
    

}

export default EndPage