import React, { Component } from 'react';
import { connect } from 'react-redux';

import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { updateFilterQuery } from './filterActions';
import { getCities } from '../../pages/EventPages/dataActions';
import Flex from '../../components/Flex';

class ResultsFilter extends Component {
	constructor (props) {
		super(props);
		this.state = {
			online: props.online || false,
			videoFilter: props.videoFilter || false,
			eventType: ''
		};
	}
	componentDidMount () {
		const { getCities, cityOptions } = this.props;
		if (cityOptions.length === 0) {
			getCities();
		}
	}

	handleChange = (event) => {
		const { updateFilterQuery } = this.props;
		const newValues = { [event.target.name]: event.target.value };
		updateFilterQuery(newValues);
		if (event.target.name === 'eventType') {
			this.setState({ eventType: event.target.value });
		}
	};

	handleOnlineChange = () => {
		const { online: prevOnline, updateFilterQuery } = this.props;
		const newValues = { online: !prevOnline };
		this.setState((prevState) => ({ online: !prevState.online }));
		updateFilterQuery(newValues);
	};

	handleVideoChange = () => {
		const { videoFilter: prevVideoFilter, updateFilterQuery } = this.props;
		const newValues = { videoFilter: !prevVideoFilter };
		this.setState((prevState) => ({ videoFilter: !prevState.videoFilter }));
		updateFilterQuery(newValues);
	};

	handleResetClick = async () => {
		const { handleFilterSearch, updateFilterQuery } = this.props;
		await updateFilterQuery({ city: '', date: '', price: '', eventType: '', online: false });
		await handleFilterSearch();
	};

	render () {
		const { city, date, price, eventType, handleFilterSearch, cityOptions, onClose } = this.props;
		const { online, videoFilter } = this.state;

		const options = [ { key: 'm', label: 'Any', value: 'Any' } ];
		if (cityOptions.length !== 0) {
			for (let i = 0; i < cityOptions.length; i++) {
				const cityName = cityOptions[i];
				options.push({ key: `${cityName}${i}`, label: cityName, value: cityName });
			}
		}
		return (
			<Flex flexDirection={'column'}>
				<FormControl>
					<InputLabel htmlFor='city-native-simple'>City</InputLabel>
					<Select
						native
						value={city}
						onChange={this.handleChange}
						inputProps={{
							name: 'city',
							id: 'city-native-simple'
						}}
					>
						{options &&
							options.map((option, index) => (
								<option
									key={`${option.value}${index}`}
									value={option.value}
								>{`${option.value}`}</option>
							))}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel htmlFor='date-native-simple'>Date</InputLabel>
					<Select
						native
						value={date}
						onChange={this.handleChange}
						inputProps={{
							name: 'date',
							id: 'date-native-simple'
						}}
					>
						<option value={'any'}>Any</option>
						<option value={'month'}>Month</option>
						<option value={'week'}>Week</option>
						<option value={'tomorrow'}>Tomorrow</option>
						<option value={0}>Sunday</option>
						<option value={1}>Monday</option>
						<option value={2}>Tuesday</option>
						<option value={3}>Wednesday</option>
						<option value={4}>Thursday</option>
						<option value={5}>Friday</option>
						<option value={6}>Saturday</option>
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel htmlFor='price-simple'>Price</InputLabel>
					<Select
						native
						value={price}
						onChange={this.handleChange}
						//labelWidth={labelWidth}
						inputProps={{
							name: 'price',
							id: 'price-simple'
						}}
					>
						<option value={0}>Any Price</option>
						<option value={1}>Free</option>
						<option value={2}>$0 - $29</option>
						<option value={3}>$30 - $99</option>
						<option value={4}>$100+</option>
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel htmlFor='event-type'>Event Type</InputLabel>
					<Select
						native
						value={eventType}
						onChange={this.handleChange}
						inputProps={{
							name: 'eventType',
							id: 'event-type'
						}}
					>
						<option value={'All'}>All</option>
						<option value={'Featured'}>Featured</option>
						<option value={'Volunteer'}>Volunteer</option>
						<option value={'Tour'}>Tour</option>
						<option value={'Taste'}>Taste</option>
						<option value={'Community'}>Community</option>
						<option value={'Learn'}>Learn</option>
					</Select>
				</FormControl>
				<FormControlLabel
					control={
						<Switch color='primary' checked={online} onChange={this.handleOnlineChange} name='online' />
					}
					label='Online Filter'
					labelPlacement='top'
					style={{ marginLeft: '-1px', marginTop: '10px' }}
				/>
				<FormControlLabel
					control={
						<Switch
							color='primary'
							checked={videoFilter}
							onChange={this.handleVideoChange}
							name='videoFilter'
						/>
					}
					label='Only Videos'
					labelPlacement='top'
					style={{ marginLeft: '-1px', marginTop: '3px' }}
				/>
				<Button
					color='primary'
					variant='contained'
					onClick={() => {
						const { eventType } = this.state;
						const { history } = this.props;
						if (eventType && eventType !== '') {
							history.push(`/searchResults/${eventType}`);
						} else {
							history.push(`/searchResults`);
						}
						if (onClose) {
							onClose();
						}
					}}
					style={{ marginBottom: '5px' }}
				>
					Search
				</Button>
				<Button
					color='secondary'
					variant='contained'
					onClick={() => {
						this.handleResetClick();
						if (onClose) {
							onClose();
						}
					}}
				>
					Clear
				</Button>
			</Flex>
		);
	}
}

const mapStateToProps = (state) => ({
	city: state.filter.city,
	price: state.filter.price,
	date: state.filter.date,
	eventType: state.filter.eventType,
	online: state.filter.online,
	videoFilter: state.filter.videoFilter,
	cityOptions: state.events.cities
});

const mapDispatchToProps = {
	updateFilterQuery,
	getCities
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsFilter);
