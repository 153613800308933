import React, { Component } from 'react';
import PropTypes from 'prop-types';

//Redux
import { connect } from 'react-redux';
import { editUserDetails } from '../userActions';

import './Setup.css';

import { Row } from 'antd';

//import { Link } from "react-router-dom";

import Music from './ActivityPics/music.png';
import Arts from './ActivityPics/arts.png';
import Cooking from './ActivityPics/cooking.png';
import Gardening from './ActivityPics/gardening.png';
import Museums from './ActivityPics/museums.png';
import Photography from './ActivityPics/photography.png';
import Reading from './ActivityPics/reading.png';
import Social from './ActivityPics/socialize.png';
import Sports from './ActivityPics/sports.png';
import Wellness from './ActivityPics/wellness.png';
import Check from '@ant-design/icons/CheckSquareOutlined';

class ActivitySetup extends Component {
	constructor (props) {
		super(props);

		this.state = {
			likeMusicDance: false,
			likeArtsCraft: false,
			likeSocialize: false,
			likeHistory: false,
			likeGardening: false,
			likeSports: false,
			likeReading: false,
			likeMuseums: false,
			likeCooking: false,
			likePhotography: false,
			likeWellness: false
		};
		this.handleClickMusic = this.handleClickMusic.bind(this);
		//this.handleClickTour = this.handleClickTour.bind(this);
		//this.handleClickVolunteer = this.handleClickVolunteer.bind(this);
		//this.handleClickLearn = this.handleClickLearn.bind(this);
		//this.handleClickCommunity = this.handleClickCommunity.bind(this);

		this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount () {
		//const { currentUser } = this.props;
		//TODO: Need to not run this if currentUser is undefined
	}

	handleClickMusic = () => {
		this.setState((state) => ({
			likeMusicDance: !this.state.likeMusicDance
		}));
	};
	handleClickArts = () => {
		this.setState((state) => ({
			likeArtsCraft: !this.state.likeArtsCraft
		}));
	};
	handleClickSocial = () => {
		this.setState((state) => ({
			likeSocialize: !this.state.likeSocialize
		}));
	};
	handleClickWellness = () => {
		this.setState((state) => ({
			likeWellness: !this.state.likeWellness
		}));
	};
	handleClickGardening = () => {
		this.setState((state) => ({
			likeGardening: !this.state.likeGardening
		}));
	};

	handleClickSports = () => {
		this.setState((state) => ({
			likeSports: !this.state.likeSports
		}));
	};
	handleClickReading = () => {
		this.setState((state) => ({
			likeReading: !this.state.likeReading
		}));
	};
	handleClickMuseums = () => {
		this.setState((state) => ({
			likeMuseums: !this.state.likeMuseums
		}));
	};
	handleClickCooking = () => {
		this.setState((state) => ({
			likeCooking: !this.state.likeCooking
		}));
	};
	handleClickPhotography = () => {
		this.setState((state) => ({
			likePhotography: !this.state.likePhotography
		}));
	};

	handleSubmit = () => {
		let userDetails = {
			...this.props.currentUser
		};
		userDetails = {
			likeMusicDance: this.state.likeMusicDance,
			likeArtsCraft: this.state.likeArtsCraft,
			likeSocialize: this.state.likeSocialize,
			likeHistory: this.state.likeHistory,
			likeGardening: this.state.likeGardening,
			likeSports: this.state.likeSports,
			likeReading: this.state.likeReading,
			likeMuseums: this.state.likeMuseums,
			likeCooking: this.state.likeCooking,
			likePhotography: this.state.likePhotography,
			likeWellness: this.state.likeWellness
		};
		this.props.parentCallback();
		this.props.editUserDetails(this.props.currentUser.uid, userDetails);
	};

	render () {
		return (
			<div className='ProfileSetup'>
				<Row type='flex' justify='center'>
					<p className='edithead'> What Are Your Interests?</p>
				</Row>

				<Row type='flex' justify='center'>
					<button className='activity' onClick={this.handleClickMusic}>
						<img className={this.state.likeMusicDance ? 'act toggles' : 'act'} src={Music} alt='' />
						{this.state.likeMusicDance ? (
							<div className='topright'>
								<Check />
							</div>
						) : null}
					</button>
					<button className='activity' onClick={this.handleClickArts}>
						<img className={this.state.likeArtsCraft ? 'act toggles' : 'act'} src={Arts} alt='' />
						{this.state.likeArtsCraft ? (
							<div className='topright'>
								<Check />
							</div>
						) : null}
					</button>
					<button className='activity' onClick={this.handleClickSocial}>
						<img className={this.state.likeSocialize ? 'act toggles' : 'act'} src={Social} alt='' />
						{this.state.likeSocialize ? (
							<div className='topright'>
								<Check />
							</div>
						) : null}
					</button>
					<button className='activity' onClick={this.handleClickWellness}>
						<img className={this.state.likeWellness ? 'act toggles' : 'act'} src={Wellness} alt='' />
						{this.state.likeWellness ? (
							<div className='topright'>
								<Check />
							</div>
						) : null}
					</button>
					<button className='activity' onClick={this.handleClickGardening}>
						<img className={this.state.likeGardening ? 'act toggles' : 'act'} src={Gardening} alt='' />
						{this.state.likeGardening ? (
							<div className='topright'>
								<Check />
							</div>
						) : null}
					</button>
				</Row>

				<Row type='flex' justify='center'>
					<button className='activity' onClick={this.handleClickSports}>
						<img className={this.state.likeSports ? 'act toggles' : 'act'} src={Sports} alt='' />
						{this.state.likeSports ? (
							<div className='topright'>
								<Check />
							</div>
						) : null}
					</button>
					<button className='activity' onClick={this.handleClickReading}>
						<img className={this.state.likeReading ? 'act toggles' : 'act'} src={Reading} alt='' />
						{this.state.likeReading ? (
							<div className='topright'>
								<Check />
							</div>
						) : null}
					</button>
					<button className='activity' onClick={this.handleClickMuseums}>
						<img className={this.state.likeMuseums ? 'act toggles' : 'act'} src={Museums} alt='' />
						{this.state.likeMuseums ? (
							<div className='topright'>
								<Check />
							</div>
						) : null}
					</button>
					<button className='activity' onClick={this.handleClickCooking}>
						<img className={this.state.likeCooking ? 'act toggles' : 'act'} src={Cooking} alt='' />
						{this.state.likeCooking ? (
							<div className='topright'>
								<Check />
							</div>
						) : null}
					</button>
					<button className='activity' onClick={this.handleClickPhotography}>
						<img className={this.state.likePhotography ? 'act toggles' : 'act'} src={Photography} alt='' />
						{this.state.likePhotography ? (
							<div className='topright'>
								<Check />
							</div>
						) : null}
					</button>
				</Row>

				<br />
				<Row type='flex' justify='center'>
					<button className='continue' onClick={this.handleSubmit}>
						Continue
					</button>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	currentUser: state.user.credentials,
	curr: state.user.currentUser
});

ActivitySetup.propTypes = {
	editUserDetails: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired
};

export default connect(mapStateToProps, { editUserDetails })(ActivitySetup);
